import { Module } from "vuex";

import { IRootState } from "@/store/types";
import { getters } from "@/store/shift/getters";
import { actions } from "@/store/shift/actions";
import { mutations } from "@/store/shift/mutations";
import { IShiftState, state } from "@/store/shift/state";

export const shift: Module<IShiftState, IRootState> = {
  actions,
  mutations,
  state,
  getters,
};
