import { MutationTree } from "vuex";
import {
  ITransactionState,
  ITransaction,
} from "@/store/transaction/state";
import { IDataProvider } from "@/store/types";

export enum TransactionMutations {
  SET_ONE = "SET_ONE_TRANSACTION_CATEGORY",
  SET_ALL = "SET_ALL_TRANSACTION_CATEGORY",
  SET_LAZY_DATA = "SET_LAZY_DATA_TRANSACTION_CATEGORY",
}

export const mutations: MutationTree<ITransactionState> = {
  [TransactionMutations.SET_ONE](state, payload: ITransaction) {
    state.transaction = payload;
  },
  [TransactionMutations.SET_ALL](state, payload: ITransaction[]) {
    state.transactions = payload;
  },
  [TransactionMutations.SET_LAZY_DATA](
    state,
    payload: IDataProvider<ITransaction>
  ) {
    state.dataProvider = payload;
  },
};
