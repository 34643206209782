import { IDataProvider } from "@/store/types";

export interface IAccount {
  id?: number;
  name: string;
  type: string;
  balance: number;
}

export interface IAccountState {
  account: IAccount;
  accounts: IAccount[];
  dataProvider: IDataProvider<IAccount>;
}

export const state: IAccountState = {
  account: {
    name: "",
    type: "",
    balance: 0,
  },
  accounts: [],
  dataProvider: {
    currentPage: 0,
    records: [],
    totalItems: 0,
    totalPages: 0,
  },
};

export enum AccountTypes {
  CASHLESS = 0,
  CASH = 1,
  BANK_CARD = 2,
}
