import { GetterTree } from "vuex";
import { IHallState, IHall } from "@/store/hall/state";
import { IRootState } from "@/store/types";

export enum HallGetters {
  GET_ALL = "GET_ALL_HALL",
  GET_CURRENT = "GET_CURRENT_HALL",
}

export const getters: GetterTree<IHallState, IRootState> = {
  [HallGetters.GET_ALL](state): IHall[] {
    return state.halls;
  },
  [HallGetters.GET_CURRENT](state): IHall {
    return state.hall;
  },
};
