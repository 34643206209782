import { IDataProvider } from "@/store/types";

export interface ITransaction {
  id: number;
  comment: string;
  accountId: number | null;
  targetAccountId: number | null;
  transactionCategoryId: number | null;
  type: number;
  amount: number;
  date: Date;
}

export interface ITransactionState {
  transaction: ITransaction | null;
  transactions: ITransaction[];
  dataProvider: IDataProvider<ITransaction>;
}

export enum TransactionTypes {
  PROFIT = 0,
  CONSUMPTION = 1,
  TRANSFER = 2,
}

export const transactionTypes = [
  {
    value: TransactionTypes.PROFIT,
    label: "Прибыль",
  },
  {
    value: TransactionTypes.CONSUMPTION,
    label: "Расход",
  },
  {
    value: TransactionTypes.TRANSFER,
    label: "Перевод",
  },
];

export const state: ITransactionState = {
  transaction: null,
  transactions: [],
  dataProvider: {
    currentPage: 0,
    records: [],
    totalItems: 0,
    totalPages: 0,
  },
};
