import { MutationTree } from "vuex";
import { IWorkshopState, IWorkshop } from "@/store/workshop/state";
import { IDataProvider } from "@/store/types";

export enum WorkshopMutations {
  SET_ONE = "SET_ONE_SUPPLIER",
  SET_ALL = "SET_ALL_SUPPLIER",
  SET_LAZY_DATA = "SET_LAZY_DATA",
}

export const mutations: MutationTree<IWorkshopState> = {
  [WorkshopMutations.SET_ONE](state, payload: IWorkshop) {
    state.workshop = payload;
  },
  [WorkshopMutations.SET_ALL](state, payload: IWorkshop[]) {
    state.workshops = payload;
  },
  [WorkshopMutations.SET_LAZY_DATA](state, payload: IDataProvider<IWorkshop>) {
    state.dataProvider = payload;
  },
};
