import { IDataProvider } from "@/store/types";
import {UserStatuses} from "@/store/user/state";

export interface ICustomer {
  id?: number;
  fullName: string;
  isMale: boolean;
  address: string;
  phone: string;
  email: string;
  birthdayDate: Date;
  comment: string;
  status: number;
  saleSum: number;
}

export interface ICustomerState {
  customer: ICustomer | null;
  customers: ICustomer[];
  dataProvider: IDataProvider<ICustomer>;
}

export const state: ICustomerState = {
  customer: null,
  customers: [],
  dataProvider: {
    currentPage: 0,
    records: [],
    totalItems: 0,
    totalPages: 0,
  },
};

export enum CustomerStatuses {
  IN_ACTIVE = 0,
  ACTIVE = 1,
}

export const customerStatuses = [
  {
    value: CustomerStatuses.IN_ACTIVE,
    label: "Отключен",
  },
  {
    value: CustomerStatuses.ACTIVE,
    label: "Активный",
  },
];
