import { MutationTree } from "vuex";
import { IShiftState, IShift } from "@/store/shift/state";
import { IDataProvider } from "@/store/types";

export enum ShiftMutations {
  SET_ONE = "SET_ONE_SHIFT",
  SET_ALL = "SET_ALL_SHIFT",
  SET_LAZY_DATA = "SET_LAZY_DATA_SHIFT",
}

export const mutations: MutationTree<IShiftState> = {
  [ShiftMutations.SET_ONE](state, payload: IShift) {
    state.shift = payload;
  },
  [ShiftMutations.SET_ALL](state, payload: IShift[]) {
    state.shifts = payload;
  },
  [ShiftMutations.SET_LAZY_DATA](state, payload: IDataProvider<IShift>) {
    state.dataProvider = payload;
  },
};
