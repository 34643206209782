import { MutationTree } from "vuex";
import { IHallState, IHall } from "@/store/hall/state";

export enum HallMutations {
  SET_ONE = "SET_ONE_HALL",
  SET_ALL = "SET_ALL_HALL",
}

export const mutations: MutationTree<IHallState> = {
  [HallMutations.SET_ONE](state, payload: IHall) {
    state.hall = payload;
  },
  [HallMutations.SET_ALL](state, payload: IHall[]) {
    state.halls = payload;
  },
};
