import { MutationTree } from "vuex";
import { ICityState, ICity } from "@/store/city/state";
import { IDataProvider } from "@/store/types";

export enum CityMutations {
  SET_ONE = "SET_ONE_CITY",
  SET_ALL = "SET_ALL_CITY",
  SET_LAZY_DATA = "SET_LAZY_DATA_CITY",
}

export const mutations: MutationTree<ICityState> = {
  [CityMutations.SET_ONE](state, payload: ICity) {
    state.city = payload;
  },
  [CityMutations.SET_ALL](state, payload: ICity[]) {
    state.cities = payload;
  },
  [CityMutations.SET_LAZY_DATA](state, payload: IDataProvider<ICity>) {
    state.dataProvider = payload;
  },
};
