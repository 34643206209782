import { IDataProvider } from "@/store/types";

export interface IOrder {
  id?: number;
  number: string;
  userId: number | null;
  customerId?: number | null;
  cost: number;
  discountSum: number;
  costTotal: number;
  cashAmount: number;
  cardAmount: number;
  payMethod: number;
  status: number;
  shiftId: number;
  comment?: string;
  customerQuantity: number;
  planId?: number | null;
  OrderItems: Array<IOrderItem>;
  createdAt?: string;
  updatedAt?: string;
}

export interface IOrderItem {
  productId?: number;
  orderId?: number;
  name: string;
  barcode: string;
  quantity: number;
  priceIn: number;
  priceRetail: number;
  status?: number;
  isToDivision?: boolean;
}

export interface IOrderState {
  order: IOrder | undefined;
  orders: IOrder[];
  dataProvider: IDataProvider<IOrder>;
}

export enum OrderStatus {
  CANCELED = 0,
  ACTIVE = 1,
  PAID = 2,
  IN_DELIVERY = 3,
  DELIVERED = 4,
  NOT_PAID = 5,
}

export enum OrderItemStatus {
  CANCELED = 0,
  ACTIVE = 1,
}

export const orderStatuses = {
  [OrderStatus.CANCELED]: "Отмена",
  [OrderStatus.ACTIVE]: "В процессе",
  [OrderStatus.PAID]: "Оплачено",
  [OrderStatus.IN_DELIVERY]: "В доставке",
  [OrderStatus.DELIVERED]: "Доставлено",
  [OrderStatus.NOT_PAID]: "Не оплачено",
};

export const orderItemStatuses = {
  [OrderItemStatus.CANCELED]: "Отменена",
  [OrderItemStatus.ACTIVE]: "Активно",
};

export const state: IOrderState = {
  order: undefined,
  orders: [],
  dataProvider: {
    currentPage: 0,
    records: [],
    totalItems: 0,
    totalPages: 0,
  },
};
