import { Module } from "vuex";
import { IRootState } from "@/store/types";

export interface ILoaderState {
  showLoader: boolean;
}

export enum LoaderActions {
  SET_LOADER = "SET_LOADER",
}

export enum LoaderMutations {
  CHANGE_LOADER = "CHANGE_LOADER",
}

export enum LoaderGetters {
  GET_LOADER = "GET_LOADER",
}

export const loader: Module<ILoaderState, IRootState> = {
  actions: {
    [LoaderActions.SET_LOADER]({ commit }, payload) {
      commit(LoaderMutations.CHANGE_LOADER, payload);
    },
  },
  mutations: {
    [LoaderMutations.CHANGE_LOADER](state, status) {
      state.showLoader = status;
    },
  },
  getters: {
    [LoaderGetters.GET_LOADER](state): boolean {
      return state.showLoader;
    },
  },
  state: {
    showLoader: false,
  },
};
