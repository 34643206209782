import { GetterTree } from "vuex";
import { ICustomerState, ICustomer } from "@/store/customer/state";
import { IDataProvider, IRootState } from "@/store/types";

export enum CustomerGetters {
  GET_ALL = "GET_ALL_CUSTOMER",
  GET_CURRENT = "GET_CURRENT_CUSTOMER",
  GET_DATA_PROVIDER = "GET_DATA_PROVIDER_CUSTOMER",
}

export const getters: GetterTree<ICustomerState, IRootState> = {
  [CustomerGetters.GET_ALL](state): ICustomer[] {
    return state.customers;
  },
  [CustomerGetters.GET_CURRENT](state): ICustomer | null {
    return state.customer;
  },
  [CustomerGetters.GET_DATA_PROVIDER](state): IDataProvider<ICustomer> {
    return state.dataProvider;
  },
};
