import { GetterTree } from "vuex";
import { IWarehouseState, IWarehouse } from "@/store/warehouse/state";
import { IDataProvider, IRootState } from "@/store/types";

export enum WarehouseGetters {
  GET_ALL = "GET_ALL_WAREHOUSE",
  GET_CURRENT = "GET_CURRENT_WAREHOUSE",
  GET_DATA_PROVIDER = "GET_DATA_PRODVIDER_WAREHOUSE",
}

export const getters: GetterTree<IWarehouseState, IRootState> = {
  [WarehouseGetters.GET_ALL](state): IWarehouse[] {
    return state.warehouses;
  },
  [WarehouseGetters.GET_CURRENT](state): IWarehouse {
    return state.warehouse;
  },
  [WarehouseGetters.GET_DATA_PROVIDER](state): IDataProvider<IWarehouse> {
    return state.dataProvider;
  },
};
