import { ActionTree } from "vuex";
import axios from "axios";

import { ICustomerState, ICustomer } from "@/store/customer/state";
import { IRootState } from "@/store/types";
import { CustomerMutations } from "@/store/customer/mutations";
import { baseApi, getUrlAddress } from "@/store/config";

export enum CustomerActions {
  CREATE_ITEM = "CREAT_ITEM_CUSTOMER",
  FETCH_ALL = "FETCH_ALL_CUSTOMER",
  UPDATE_ITEM = "UPDATE_ITEM_CUSTOMER",
  DELETE_ITEM = "DELETE_ITEM_CUSTOMER",
  SET_ITEM = "SET_ITEM_CUSTOMER",
  FETCH_ONE = "FETCH_ONE_CUSTOMER",
  FETCH_LAZY_DATA = "FETCH_LAZY_DATA_CUSTOMER",
}

const routeName = `customer`;

export const actions: ActionTree<ICustomerState, IRootState> = {
  [CustomerActions.FETCH_LAZY_DATA]({ commit }, params) {
    return new Promise((resolve, reject) => {
      baseApi
        .get(`${getUrlAddress(routeName)}/data-provider`, {
          params: params,
        })
        .then((response) => {
          commit(CustomerMutations.SET_LAZY_DATA, response.data);
          resolve(true);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  [CustomerActions.FETCH_ALL]({ commit }, params) {
    return new Promise((resolve, reject) => {
      baseApi
        .get(`${getUrlAddress(routeName)}/index`, {
          params: params,
        })
        .then((response) => {
          commit(CustomerMutations.SET_ALL, response.data);
          resolve(true);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  [CustomerActions.FETCH_ONE]({ commit }, id) {
    return new Promise((resolve, reject) => {
      baseApi
        .get(`${getUrlAddress(routeName)}/get`, {
          params: {
            id,
          },
        })
        .then((response) => {
          commit(CustomerMutations.SET_ONE, response.data);
          resolve(true);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  [CustomerActions.CREATE_ITEM]({ commit, dispatch }, payload: ICustomer) {
    return new Promise((resolve, reject) => {
      baseApi
        .post(`${getUrlAddress(routeName)}/create`, payload)
        .then(() => {
          commit(CustomerMutations.SET_ONE, payload);
          resolve(true);
        })
        .catch(function (error) {
          reject(error);
        });
    });
  },
  [CustomerActions.UPDATE_ITEM]({ dispatch }, payload: ICustomer) {
    return new Promise((resolve, reject) => {
      baseApi
        .put(`${getUrlAddress(routeName)}/update?id=${payload.id}`, payload)
        .then(() => {
          resolve(true);
        })
        .catch(function (error) {
          reject(error);
        });
    });
  },
  [CustomerActions.SET_ITEM]({ commit }, payload) {
    commit(CustomerMutations.SET_ONE, payload);
  },
  [CustomerActions.DELETE_ITEM]({ dispatch }, payload) {
    return new Promise((resolve, reject) => {
      baseApi
        .delete(`${getUrlAddress(routeName)}/delete?id=${payload.id}`)
        .then(() => {
          resolve(true);
        })
        .catch(function (error) {
          reject(error);
        });
    });
  },
};
