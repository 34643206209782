import { IDataProvider } from "@/store/types";

export interface IShift {
  id?: number;
  userId: number;
  sumAtOpen: number;
  sumAtClose: number;
  collection: number;
  status: number;
  comment?: string;
  openedAt: string;
  closedAt: string;
}

export interface IShiftState {
  shift: IShift;
  shifts: IShift[];
  dataProvider: IDataProvider<IShift>;
}

export enum ShiftStatus {
  IN_ACTIVE = 0,
  ACTIVE = 1,
}

export const state: IShiftState = {
  shift: {
    userId: 0,
    sumAtOpen: 0,
    sumAtClose: 0,
    collection: 0,
    status: 0,
    comment: "",
    openedAt: "",
    closedAt: "",
  },
  shifts: [],
  dataProvider: {
    currentPage: 0,
    records: [],
    totalItems: 0,
    totalPages: 0,
  },
};
