import { Module } from "vuex";

import { IRootState } from "@/store/types";
import { getters } from "@/store/transactionCategory/getters";
import { actions } from "@/store/transactionCategory/actions";
import { mutations } from "@/store/transactionCategory/mutations";
import {
  ITransactionCategoryState,
  state,
} from "@/store/transactionCategory/state";

export const transactionCategory: Module<
  ITransactionCategoryState,
  IRootState
> = {
  actions,
  mutations,
  state,
  getters,
};
