import { MutationTree } from "vuex";
import { IPaymentState, IPayment } from "@/store/payment/state";
import { IDataProvider } from "@/store/types";

export enum PaymentMutations {
  SET_ONE = "SET_ONE_PAYMENT",
  SET_ALL = "SET_ALL_PAYMENT",
  SET_LAZY_DATA = "SET_LAZY_DATA_PAYMENT",
}

export const mutations: MutationTree<IPaymentState> = {
  [PaymentMutations.SET_ONE](state, payload: IPayment) {
    state.payment = payload;
  },
  [PaymentMutations.SET_ALL](state, payload: IPayment[]) {
    state.payments = payload;
  },
  [PaymentMutations.SET_LAZY_DATA](state, payload: IDataProvider<IPayment>) {
    state.dataProvider = payload;
  },
};
