import { createStore, createLogger } from "vuex";
import createPersistedState from "vuex-persistedstate";

import { auth } from "@/store/auth";
import { plan } from "@/store/plan";
import { hall } from "@/store/hall";
import { supplier } from "@/store/supplier";
import { supply } from "@/store/supply";
import { warehouse } from "@/store/warehouse";
import { account } from "@/store/account";
import { category } from "@/store/category";
import { product } from "@/store/product";
import { workshop } from "@/store/workshop";
import { shift } from "@/store/shift";
import { user } from "@/store/user";
import { order } from "@/store/order";
import { setting } from "@/store/setting";
import { loader } from "@/store/loader";
import { takeOff } from "@/store/takeOff";
import { revision } from "@/store/revision";
import { region } from "@/store/region";
import { city } from "@/store/city";
import { facility } from "@/store/facility";
import { payment } from "@/store/payment";
import { customer } from "@/store/customer";
import { pack } from "@/store/pack";
import { transaction } from "@/store/transaction";
import { transactionCategory } from "@/store/transactionCategory";
import { IRootState } from "@/store/types";

export const apiAddress = `http://localhost:4040`;

const plugins = [];
if (process.env.NODE_ENV === "development") {
  plugins.push(createLogger());
}

export const store = createStore<IRootState>({
  state: {
    version: 0,
  },
  mutations: {},
  actions: {},
  modules: {
    auth,
    plan,
    hall,
    supplier,
    supply,
    warehouse,
    account,
    category,
    product,
    workshop,
    shift,
    user,
    order,
    setting,
    loader,
    takeOff,
    revision,
    region,
    city,
    facility,
    payment,
    customer,
    pack,
    transaction,
    transactionCategory,
  },
  plugins: [createPersistedState()],
});
