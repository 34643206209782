import { Module } from "vuex";

import { IRootState } from "@/store/types";
import { getters } from "@/store/warehouse/getters";
import { actions } from "@/store/warehouse/actions";
import { mutations } from "@/store/warehouse/mutations";
import { IWarehouseState, state } from "@/store/warehouse/state";

export const warehouse: Module<IWarehouseState, IRootState> = {
  actions,
  mutations,
  state,
  getters,
};
