import { Module } from "vuex";

import { IRootState } from "@/store/types";
import { getters } from "@/store/order/getters";
import { actions } from "@/store/order/actions";
import { mutations } from "@/store/order/mutations";
import { IOrderState, state } from "@/store/order/state";

export const order: Module<IOrderState, IRootState> = {
  actions,
  mutations,
  state,
  getters,
};
