import { MutationTree } from "vuex";
import { IRevisionState, IRevision } from "@/store/revision/state";
import { IDataProvider } from "@/store/types";

export enum RevisionMutations {
  SET_ONE = "SET_REVISION",
  SET_ALL = "SET_ALL_REVISION",
  SET_LAZY_DATA = "SET_LAZY_DATA_REVISION",
}

export const mutations: MutationTree<IRevisionState> = {
  [RevisionMutations.SET_ONE](state, payload: IRevision) {
    state.revision = payload;
  },
  [RevisionMutations.SET_ALL](state, payload: IRevision[]) {
    state.revisions = payload;
  },
  [RevisionMutations.SET_LAZY_DATA](state, payload: IDataProvider<IRevision>) {
    state.dataProvider = payload;
  },
};
