
import { defineComponent } from "vue";
import MainLayout from "@/views/layouts/MainLayout.vue";
import EmptyLayout from "@/views/layouts/EmptyLayout.vue";
import AuthLayout from "@/views/layouts/AuthLayout.vue";

export default defineComponent({
  name: "App",
  computed: {
    layout() {
      return (this.$route.meta.layout || "main") + "-layout";
    },
  },
  components: {
    MainLayout,
    EmptyLayout,
    AuthLayout,
  },
});
