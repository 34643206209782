import { ActionTree } from "vuex";
import axios from "axios";

import { IHallState, IHall } from "@/store/hall/state";
import { IRootState } from "@/store/types";
import { HallMutations } from "@/store/hall/mutations";
import {baseApi, getUrlAddress} from "@/store/config";

export enum HallActions {
  FETCH_ALL = "FETCH_ALL_HALL",
  FETCH_ONE = "FETCH_ONE_HALL",
}

const routeName = `hall`;

export const actions: ActionTree<IHallState, IRootState> = {
  [HallActions.FETCH_ALL]({ commit }) {
    return new Promise((resolve, reject) => {
      baseApi
        .get(`${getUrlAddress(routeName)}/index`)
        .then((response) => {
          commit(HallMutations.SET_ALL, response.data);
          resolve(true);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  [HallActions.FETCH_ONE]({ commit }, id) {
    return new Promise((resolve, reject) => {
      baseApi
        .get(`${getUrlAddress(routeName)}/${id}`)
        .then((response) => {
          commit(HallMutations.SET_ONE, response.data);
          resolve(true);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
};
