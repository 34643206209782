import { GetterTree } from "vuex";
import { ISupplierState, ISupplier } from "@/store/supplier/state";
import { IDataProvider, IRootState } from "@/store/types";

export enum SupplierGetters {
  GET_ALL = "GET_ALL_SUPPLIER",
  GET_CURRENT = "GET_CURRENT_SUPPLIER",
  GET_DATA_PROVIDER = "GET_DATA_PROVIDER_SUPPLIER",
}

export const getters: GetterTree<ISupplierState, IRootState> = {
  [SupplierGetters.GET_ALL](state): ISupplier[] {
    return state.suppliers;
  },
  [SupplierGetters.GET_CURRENT](state): ISupplier {
    return state.supplier;
  },
  [SupplierGetters.GET_DATA_PROVIDER](state): IDataProvider<ISupplier> {
    return state.dataProvider;
  },
};
