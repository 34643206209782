import { IDataProvider } from "@/store/types";
import { UserStatuses } from "@/store/user/state";

export interface ITransactionCategory {
  id: number;
  name: string;
  parentId: string | null;
  type: number;
}

export interface ITransactionCategoryState {
  transactionCategory: ITransactionCategory | null;
  transactionCategories: ITransactionCategory[];
  dataProvider: IDataProvider<ITransactionCategory>;
}

export enum TransactionCategoryTypes {
  PROFIT = 0,
  CONSUMPTION = 1,
}

export const transactionCategoryTypes = [
  {
    value: TransactionCategoryTypes.PROFIT,
    label: "Прибыль",
  },
  {
    value: TransactionCategoryTypes.CONSUMPTION,
    label: "Расход",
  },
];

export const state: ITransactionCategoryState = {
  transactionCategory: null,
  transactionCategories: [],
  dataProvider: {
    currentPage: 0,
    records: [],
    totalItems: 0,
    totalPages: 0,
  },
};
