import { Module } from "vuex";

import { IRootState } from "@/store/types";
import { getters } from "@/store/transaction/getters";
import { actions } from "@/store/transaction/actions";
import { mutations } from "@/store/transaction/mutations";
import { ITransactionState, state } from "@/store/transaction/state";

export const transaction: Module<ITransactionState, IRootState> = {
  actions,
  mutations,
  state,
  getters,
};
