import { GetterTree } from "vuex";
import { ICityState, ICity } from "@/store/city/state";

import { IDataProvider, IRootState } from "@/store/types";

export enum CityGetters {
  GET_ALL = "GET_ALL_CITY",
  GET_CURRENT = "GET_CURRENT_CITY",
  GET_DATA_PROVIDER = "GET_DATA_PROVIDER_CITY",
}

export const getters: GetterTree<ICityState, IRootState> = {
  [CityGetters.GET_ALL](state): ICity[] {
    return state.cities;
  },
  [CityGetters.GET_CURRENT](state): ICity | null {
    return state.city;
  },
  [CityGetters.GET_DATA_PROVIDER](state): IDataProvider<ICity> {
    return state.dataProvider;
  },
};
