import { GetterTree } from "vuex";
import { IShiftState, IShift } from "@/store/shift/state";

import { IDataProvider, IRootState } from "@/store/types";

export enum ShiftGetters {
  GET_ALL = "GET_ALL_SHIFT",
  GET_CURRENT = "GET_CURRENT_SHIFT",
  GET_DATA_PROVIDER = "GET_DATA_PROVIDER_SHIFT",
}

export const getters: GetterTree<IShiftState, IRootState> = {
  [ShiftGetters.GET_ALL](state): IShift[] {
    return state.shifts;
  },
  [ShiftGetters.GET_CURRENT](state): IShift {
    return state.shift;
  },
  [ShiftGetters.GET_DATA_PROVIDER](state): IDataProvider<IShift> {
    return state.dataProvider;
  },
};
