import { GetterTree } from "vuex";
import { ISupplyState, ISupply } from "@/store/supply/state";
import { IDataProvider, IRootState } from "@/store/types";

export enum SupplyGetters {
  GET_ALL = "GET_ALL_SUPPLY",
  GET_CURRENT = "GET_CURRENT_SUPPLY",
  GET_DATA_PROVIDER = "GET_DATA_PROVIDER_SUPPLY",
}

export const getters: GetterTree<ISupplyState, IRootState> = {
  [SupplyGetters.GET_ALL](state): ISupply[] {
    return state.supplies;
  },
  [SupplyGetters.GET_CURRENT](state): ISupply | null {
    return state.supply;
  },
  [SupplyGetters.GET_DATA_PROVIDER](state): IDataProvider<ISupply> {
    return state.dataProvider;
  },
};
