import { ActionTree } from "vuex";

import { IPackState, IPack } from "@/store/pack/state";
import { IRootState } from "@/store/types";
import { PackMutations } from "@/store/pack/mutations";
import { baseApi, getUrlAddress } from "@/store/config";

export enum PackActions {
  CREATE_ITEM = "CREAT_ITEM_PACK",
  FETCH_ALL = "FETCH_ALL_PACK",
  UPDATE_ITEM = "UPDATE_ITEM_PACK",
  DELETE_ITEM = "DELETE_ITEM_PACK",
  SET_ITEM = "SET_ITEM__PACK",
  FETCH_ONE = "FETCH_ONE_PACK",
  FETCH_LAZY_DATA = "FETCH_LAZY_DATA_PACK",
}

const routeName = `pack`;

export const actions: ActionTree<IPackState, IRootState> = {
  [PackActions.FETCH_LAZY_DATA]({ commit }, params) {
    return new Promise((resolve, reject) => {
      baseApi
        .get(`${getUrlAddress(routeName)}/data-provider`, {
          params: params,
        })
        .then((response) => {
          commit(PackMutations.SET_LAZY_DATA, response.data);
          resolve(true);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  [PackActions.FETCH_ALL]({ commit }, params) {
    return new Promise((resolve, reject) => {
      baseApi
        .get(`${getUrlAddress(routeName)}/index`, {
          params: params,
        })
        .then((response) => {
          commit(PackMutations.SET_ALL, response.data);
          resolve(true);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  [PackActions.FETCH_ONE]({ commit }, id) {
    return new Promise((resolve, reject) => {
      baseApi
        .get(`${getUrlAddress(routeName)}/${id}`)
        .then((response) => {
          commit(PackMutations.SET_ONE, response.data);
          resolve(true);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  [PackActions.CREATE_ITEM]({ commit, dispatch }, payload: IPack) {
    return new Promise((resolve, reject) => {
      baseApi
        .post(`${getUrlAddress(routeName)}/create`, payload)
        .then(() => {
          commit(PackMutations.SET_ONE, payload);
          resolve(true);
        })
        .catch(function (error) {
          reject(error);
        });
    });
  },
  [PackActions.UPDATE_ITEM]({ dispatch }, payload: IPack) {
    return new Promise((resolve, reject) => {
      baseApi
        .put(`${getUrlAddress(routeName)}/update?id=${payload.id}`, {
          data: payload,
        })
        .then(() => {
          resolve(true);
        })
        .catch(function (error) {
          reject(error);
        });
    });
  },
  [PackActions.DELETE_ITEM]({ dispatch }, payload) {
    return new Promise((resolve, reject) => {
      baseApi
        .delete(`${getUrlAddress(routeName)}/delete?id=${payload.id}`)
        .then(() => {
          resolve(true);
        })
        .catch(function (error) {
          reject(error);
        });
    });
  },
};
