import { MutationTree } from "vuex";
import { IFacilityState, IFacility } from "@/store/facility/state";
import { IDataProvider } from "@/store/types";

export enum FacilityMutations {
  SET_ONE = "SET_ONE_FACILITY",
  SET_ALL = "SET_ALL_FACILITY",
  SET_LAZY_DATA = "SET_LAZY_DATA_FACILITY",
}

export const mutations: MutationTree<IFacilityState> = {
  [FacilityMutations.SET_ONE](state, payload: IFacility) {
    state.facility = payload;
  },
  [FacilityMutations.SET_ALL](state, payload: IFacility[]) {
    state.facilities = payload;
  },
  [FacilityMutations.SET_LAZY_DATA](state, payload: IDataProvider<IFacility>) {
    state.dataProvider = payload;
  },
};
