import { ActionTree } from "vuex";

import { ITakeOffState, ITakeOff } from "@/store/takeOff/state";
import { IRootState } from "@/store/types";
import { TakeOffMutations } from "@/store/takeOff/mutations";
import { baseApi, getUrlAddress } from "@/store/config";

export enum TakeOffActions {
  FETCH_ALL = "FETCH_ALL_TAKE_OFF",
  FETCH_ONE = "FETCH_ONE_TAKE_OFF",
  FETCH_LAZY_DATA = "FETCH_LAZY_DATA_TAKE_OFF",
}

const routeName = `take-off`;

export const actions: ActionTree<ITakeOffState, IRootState> = {
  [TakeOffActions.FETCH_LAZY_DATA]({ commit }, params) {
    return new Promise((resolve, reject) => {
      baseApi
        .get(`${getUrlAddress(routeName)}/data-provider`, {
          params: params,
        })
        .then((response) => {
          commit(TakeOffMutations.SET_LAZY_DATA, response.data);
          resolve(true);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  [TakeOffActions.FETCH_ALL]({ commit }, params) {
    return new Promise((resolve, reject) => {
      baseApi
        .get(`${getUrlAddress(routeName)}/index`, {
          params: params,
        })
        .then((response) => {
          commit(TakeOffMutations.SET_ALL, response.data);
          resolve(true);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  [TakeOffActions.FETCH_ONE]({ commit }, id) {
    return new Promise((resolve, reject) => {
      baseApi
        .get(`${getUrlAddress(routeName)}/get`, {
          params: {
            id,
          },
        })
        .then((response) => {
          commit(TakeOffMutations.SET_ONE, response.data);
          resolve(true);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
};
