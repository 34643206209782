import { MutationTree } from "vuex";
import { IWarehouseState, IWarehouse } from "@/store/warehouse/state";
import { IDataProvider } from "@/store/types";

export enum WarehouseMutations {
  SET_ONE = "SET_ONE_WAREHOUSE",
  SET_ALL = "SET_ALL_WAREHOUSE",
  SET_LAZY_DATA = "SET_LAZY_DATA_WAREHOUSE",
}

export const mutations: MutationTree<IWarehouseState> = {
  [WarehouseMutations.SET_ONE](state, payload: IWarehouse) {
    state.warehouse = payload;
  },
  [WarehouseMutations.SET_ALL](state, payload: IWarehouse[]) {
    state.warehouses = payload;
  },
  [WarehouseMutations.SET_LAZY_DATA](
    state,
    payload: IDataProvider<IWarehouse>
  ) {
    state.dataProvider = payload;
  },
};
