import { IDataProvider } from "@/store/types";

export interface ISetting {
  id?: number;
  name: string;
  value: string;
}

export interface ISettingState {
  setting: ISetting;
  settings: ISetting[];
  dataProvider: IDataProvider<ISetting>;
}

export const state: ISettingState = {
  setting: {
    name: "",
    value: "",
  },
  settings: [],
  dataProvider: {
    currentPage: 0,
    records: [],
    totalItems: 0,
    totalPages: 0,
  },
};

export enum SettingsTypes {
  PRINTER_BILL = "printerBill",
  PRINTER_LABEL = "printerLabel",
  DOUBLE_BILL = "doubleBill",
  LOGO = "logo",
  OBJECT_NAME = "name",
  OBJECT_ADDRESS = "address",
  OBJECT_PHONE = "phone",
  OBJECT_TOKEN = "token",
  EXPIRE_DATE = "expire_date",
  BILL_MESSAGE = "bill_message",
  IS_SHOW_OBJECT_NAME = "is_show_object_name",
  IS_PRINT_DOUBLE_BILL = "is_print_double_bill",
}
