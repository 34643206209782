import { Module } from "vuex";

import { IRootState } from "@/store/types";
import { getters } from "@/store/payment/getters";
import { actions } from "@/store/payment/actions";
import { mutations } from "@/store/payment/mutations";
import { IPaymentState, state } from "@/store/payment/state";

export const payment: Module<IPaymentState, IRootState> = {
  actions,
  mutations,
  state,
  getters,
};
