import { IDataProvider } from "@/store/types";

export interface ISupplier {
  id?: number;
  name: string;
  number?: string;
  phone?: string;
  address?: string;
  comment?: string;
  createdAt?: string;
  updatedAt?: string;
}

export interface ISupplierState {
  supplier: ISupplier;
  suppliers: ISupplier[];
  dataProvider: IDataProvider<ISupplier>;
}

export const state: ISupplierState = {
  supplier: {
    name: "",
  },
  suppliers: [],
  dataProvider: {
    currentPage: 0,
    records: [],
    totalItems: 0,
    totalPages: 0,
  },
};
