import { ActionTree } from "vuex";

import { IRegionState, IRegion } from "@/store/region/state";
import { IRootState } from "@/store/types";
import { RegionMutations } from "@/store/region/mutations";
import { baseApi, getUrlAddress } from "@/store/config";

export enum RegionActions {
  CREATE_ITEM = "CREAT_ITEM_REGION",
  FETCH_ONE = "FETCH_ONE_REGION",
  FETCH_ALL = "FETCH_ALL_REGION",
  UPDATE_ITEM = "UPDATE_ITEM_REGION",
}

const routeName = `region`;

export const actions: ActionTree<IRegionState, IRootState> = {
  [RegionActions.FETCH_ALL]({ commit }) {
    return new Promise((resolve, reject) => {
      baseApi
        .get(`${getUrlAddress(routeName)}/index`)
        .then((response) => {
          commit(RegionMutations.SET_ALL, response.data);
          resolve(true);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  [RegionActions.FETCH_ONE]({ commit }, id) {
    return new Promise((resolve, reject) => {
      baseApi
        .get(`${getUrlAddress(routeName)}/${id}`)
        .then((response) => {
          commit(RegionMutations.SET_ONE, response.data);
          resolve(true);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  [RegionActions.CREATE_ITEM]({ commit, dispatch }, payload: IRegion) {
    return new Promise((resolve, reject) => {
      baseApi
        .post(`${getUrlAddress(routeName)}/create`, payload)
        .then(() => {
          commit(RegionMutations.SET_ONE, payload);
          resolve(true);
        })
        .catch(function (error) {
          reject(error);
        });
    });
  },
  [RegionActions.UPDATE_ITEM]({ dispatch }, payload: IRegion) {
    return new Promise((resolve, reject) => {
      baseApi
        .put(`${getUrlAddress(routeName)}/update?id=${payload.id}`, {
          data: payload,
        })
        .then(() => {
          resolve(true);
        })
        .catch(function (error) {
          reject(error);
        });
    });
  },
};
