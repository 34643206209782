import { ActionTree } from "vuex";
import axios from "axios";

import { ICategoryState, ICategory } from "@/store/category/state";
import { IRootState } from "@/store/types";
import { CategoryMutations } from "@/store/category/mutations";
import {baseApi, getUrlAddress} from "@/store/config";

export enum CategoryActions {
  FETCH_ONE = "FETCH_ONE_CATEGORY",
  FETCH_ALL = "FETCH_ALL_CATEGORY",
  FETCH_LAZY_DATA = "FETCH_LAZY_DATA_CATEGORY",
}

const routeName = `category`;

export const actions: ActionTree<ICategoryState, IRootState> = {
  [CategoryActions.FETCH_LAZY_DATA]({ commit }, params) {
    return new Promise((resolve, reject) => {
      baseApi
        .get(`${getUrlAddress(routeName)}/data-provider`, {
          params: params,
        })
        .then((response) => {
          commit(CategoryMutations.SET_LAZY_DATA, response.data);
          resolve(true);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  [CategoryActions.FETCH_ALL]({ commit }, params) {
    return new Promise((resolve, reject) => {
      baseApi
        .get(`${getUrlAddress(routeName)}/index`, {
          params: params,
        })
        .then((response) => {
          commit(CategoryMutations.SET_ALL, response.data);
          resolve(true);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  [CategoryActions.FETCH_ONE]({ commit }, id) {
    return new Promise((resolve, reject) => {
      baseApi
        .get(`${getUrlAddress(routeName)}/${id}`)
        .then((response) => {
          commit(CategoryMutations.SET_ONE, response.data);
          resolve(true);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
};
