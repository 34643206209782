import { ActionTree } from "vuex";
import axios from "axios";

import { ITransactionCategoryState, ITransactionCategory } from "@/store/transactionCategory/state";
import { IRootState } from "@/store/types";
import { TransactionCategoryMutations } from "@/store/transactionCategory/mutations";
import { baseApi, getUrlAddress } from "@/store/config";

export enum TransactionCategoryActions {
  CREATE_ITEM = "CREAT_ITEM_TRANSACTION_CATEGORY",
  FETCH_ONE = "FETCH_ONE_TRANSACTION_CATEGORY",
  FETCH_ALL = "FETCH_ALL_TRANSACTION_CATEGORY",
  FETCH_LAZY_DATA = "FETCH_LAZY_DATA_TRANSACTION_CATEGORY",
  UPDATE_ITEM = "UPDATE_ITEM_TRANSACTION_CATEGORY",
  DELETE_ITEM = "DELETE_ITEM_TRANSACTION_CATEGORY",
  SET_ITEM = "SET_ITEM_TRANSACTION_CATEGORY",
}

const routeName = `transaction-category`;

export const actions: ActionTree<ITransactionCategoryState, IRootState> = {
  [TransactionCategoryActions.FETCH_LAZY_DATA]({ commit }, params) {
    return new Promise((resolve, reject) => {
      baseApi
        .get(`${getUrlAddress(routeName)}/data-provider`, {
          params: params,
        })
        .then((response) => {
          commit(TransactionCategoryMutations.SET_LAZY_DATA, response.data);
          resolve(true);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  [TransactionCategoryActions.FETCH_ALL]({ commit }, params) {
    return new Promise((resolve, reject) => {
      baseApi
        .get(`${getUrlAddress(routeName)}/index`, {
          params: params,
        })
        .then((response) => {
          commit(TransactionCategoryMutations.SET_ALL, response.data);
          resolve(true);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  [TransactionCategoryActions.FETCH_ONE]({ commit }, id) {
    return new Promise((resolve, reject) => {
      baseApi
        .get(`${getUrlAddress(routeName)}/get`, {
          params: {
            id,
          },
        })
        .then((response) => {
          commit(TransactionCategoryMutations.SET_ONE, response.data);
          resolve(true);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  [TransactionCategoryActions.SET_ITEM]({ commit }, payload) {
    commit(TransactionCategoryMutations.SET_ONE, payload);
  },
};
