import { GetterTree } from "vuex";
import { IFacilityState, IFacility } from "@/store/facility/state";

import { IDataProvider, IRootState } from "@/store/types";

export enum FacilityGetters {
  GET_ALL = "GET_ALL_FACILITY",
  GET_CURRENT = "GET_CURRENT_FACILITY",
  GET_DATA_PROVIDER = "GET_DATA_PROVIDER_FACILITY",
}

export const getters: GetterTree<IFacilityState, IRootState> = {
  [FacilityGetters.GET_ALL](state): IFacility[] {
    return state.facilities;
  },
  [FacilityGetters.GET_CURRENT](state): IFacility | null {
    return state.facility;
  },
  [FacilityGetters.GET_DATA_PROVIDER](state): IDataProvider<IFacility> {
    return state.dataProvider;
  },
};
