import { GetterTree } from "vuex";
import { IWorkshopState, IWorkshop } from "@/store/workshop/state";
import { IDataProvider, IRootState } from "@/store/types";

export enum WorkshopGetters {
  GET_ALL = "GET_ALL_WORKSHOP",
  GET_CURRENT = "GET_CURRENT_WORKSHOP",
  GET_DATA_PROVIDER = "GET_DATA_PROVIDER_WORKSHOP",
}

export const getters: GetterTree<IWorkshopState, IRootState> = {
  [WorkshopGetters.GET_ALL](state): IWorkshop[] {
    return state.workshops;
  },
  [WorkshopGetters.GET_CURRENT](state): IWorkshop {
    return state.workshop;
  },
  [WorkshopGetters.GET_DATA_PROVIDER](state): IDataProvider<IWorkshop> {
    return state.dataProvider;
  },
};
