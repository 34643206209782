import { MutationTree } from "vuex";
import { IPlan, IPlanState } from "@/store/plan/state";

export enum PlanMutations {
  SET_ALL = "SET_ALL_PLAN",
}

export const mutations: MutationTree<IPlanState> = {
  [PlanMutations.SET_ALL](state, payload: IPlan[]) {
    state.plans = payload;
  },
};
