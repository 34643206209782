
import { defineComponent, ref, onMounted } from "vue";
import Toolbar from "primevue/toolbar";
import Panel from "primevue/panel";
import { FilterMatchMode } from "primevue/api";
import { IOrder } from "@/store/order/state";
import { useStore } from "vuex";
import { OrderActions } from "@/store/order/actions";
import { OrderGetters } from "@/store/order/getters";
import Chart from "primevue/chart";
import { convertDateToMySqlDateTimeFormat } from "@/helpers";

export default defineComponent({
  name: "Sales",
  components: {
    Toolbar,
    Panel,
    Chart,
  },
  setup() {
    const store = useStore();

    let dateCurrent = new Date();

    let datePast = new Date();
    datePast.setHours(0, 0, 0);
    datePast.setDate(datePast.getDate() - 10);

    const date = ref([datePast, dateCurrent]);
    const orders = ref<Array<IOrder>>([]);

    const proceeds = ref(0);
    const profit = ref("0");
    const customersQuantity = ref(0);
    const averageSum = ref("0");

    const root = ref<Chart>();

    onMounted(() => {
      calendarPickHandler();
    });

    const basicData = ref({
      labels: [""],
      datasets: [
        {
          label: "Выручка",
          backgroundColor: "#42A5F5",
          data: [0],
        },
      ],
    });

    async function calendarPickHandler() {
      let dateStart = new Date(date.value[0].getTime());
      let dateEnd = new Date(date.value[1].getTime());
      dateStart.setHours(0,0,0);
      dateEnd.setHours(23, 59, 59);

      await store.dispatch(OrderActions.FETCH_ALL, {
        filters: {
          createdAt: {
            value: [
              convertDateToMySqlDateTimeFormat(dateStart),
              convertDateToMySqlDateTimeFormat(dateEnd),
            ],
            matchMode: FilterMatchMode.DATE_IS,
          },
        },
      });

      orders.value = await store.getters[OrderGetters.GET_ALL];

      let ordersCostPrice = 0;
      if (orders.value.length > 0) {
        orders.value.forEach((order) => {
          proceeds.value += order.costTotal;
          customersQuantity.value += order.customerQuantity;
          order.OrderItems.forEach((orderItem) => {
            ordersCostPrice += orderItem.priceIn;
          });
        });

        profit.value = (proceeds.value - ordersCostPrice).toFixed(2);
        averageSum.value = (proceeds.value / orders.value.length).toFixed(2);

        // Get dates array label and value
        const dates = getDates(
          new Date(date.value[0].getTime()),
          new Date(date.value[1].getTime())
        );
        basicData.value.labels = dates;

        let chartDataset: number[] = [];

        dates.forEach(function (date) {
          let sum = 0;
          orders.value.forEach(function (order) {
            if (!order.createdAt) {
              throw Error("created does not exist");
            }

            if (date === new Date(order.createdAt).toLocaleDateString()) {
              sum += order.costTotal;
            }
          });
          chartDataset.push(sum);
        });
        basicData.value.datasets[0].data = chartDataset;
      } else {
        proceeds.value = 0;
        profit.value = "0";
        customersQuantity.value = 0;
        averageSum.value = "0";

        basicData.value.labels = getDates(
          new Date(date.value[0].getTime()),
          new Date(date.value[1].getTime())
        );
        basicData.value.datasets[0].data = [0];
      }
      root.value?.refresh();
    }

    function getDates(startDate: Date, endDate: Date) {
      const dates = [];
      let currentDate = startDate;
      const addDays = function (this: Date, days: number) {
        const date = new Date(this.valueOf());
        date.setDate(date.getDate() + days);
        return date;
      };
      while (currentDate <= endDate) {
        dates.push(currentDate.toLocaleDateString());
        currentDate = addDays.call(currentDate, 1);
      }
      return dates;
    }

    return {
      date,
      calendarPickHandler,
      orders,
      proceeds,
      profit,
      customersQuantity,
      averageSum,
      basicData,
      root,
    };
  },
});
