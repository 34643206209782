<template>
  <div class="layout-topbar">
    <button class="p-link layout-menu-button" @click="onMenuToggle">
      <span class="pi pi-bars"></span>
    </button>
    <div class="layout-topbar-icons">
      <button class="p-link">
        <span class="layout-topbar-item-text">Настройки</span>
        <span class="layout-topbar-icon pi pi-cog"></span>
      </button>
      <button class="p-link" @click="signOutHandler">
        <span class="layout-topbar-item-text">Выйти</span>
        <span class="layout-topbar-icon pi pi-sign-out"></span>
      </button>
    </div>
  </div>
</template>

<script>
import {AuthActions} from "@/store/auth";

export default {
  methods: {
    onMenuToggle(event) {
      this.$emit("menu-toggle", event);
    },
    async signOutHandler() {
      this.$store.dispatch(AuthActions.LOGOUT);
      await this.$router.push("/login");
    },
  },
};
</script>
