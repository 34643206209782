import { MutationTree } from "vuex";
import { IOrderState, IOrder } from "@/store/order/state";
import { IDataProvider } from "@/store/types";

export enum OrderMutations {
  SET_ONE = "SET_ONE_ORDER",
  SET_ALL = "SET_ALL_ORDER",
  SET_LAZY_DATA = "SET_LAZY_DATA_ORDER",
}

export const mutations: MutationTree<IOrderState> = {
  [OrderMutations.SET_ONE](state, payload: IOrder) {
    state.order = payload;
  },
  [OrderMutations.SET_ALL](state, payload: IOrder[]) {
    state.orders = payload;
  },
  [OrderMutations.SET_LAZY_DATA](state, payload: IDataProvider<IOrder>) {
    state.dataProvider = payload;
  },
};
