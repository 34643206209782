import { GetterTree } from "vuex";
import {ISettingState, ISetting, SettingsTypes} from "@/store/setting/state";

import { IRootState } from "@/store/types";

export enum SettingGetters {
  GET_ALL = "GET_ALL_SETTINGS",
  GET_CURRENT = "GET_CURRENT_SETTINGS",
  GET_EXPIRE_DATE = "GET_EXPIRE_DATE",
  GET_BILL_MESSAGE = "GET_BILL_MESSAGE",
  GET_LOGO = "GET_LOGO",
  GET_IS_SHOW_OBJECT_NAME = "GET_IS_SHOW_OBJECT_NAME",
  GET_IS_PRINT_DOUBLE_BILL = "GET_IS_PRINT_DOUBLE_BILL",
}

export const getters: GetterTree<ISettingState, IRootState> = {
  [SettingGetters.GET_ALL](state): ISetting[] {
    return state.settings;
  },
  [SettingGetters.GET_CURRENT](state): ISetting {
    return state.setting;
  },
  [SettingGetters.GET_EXPIRE_DATE](state): string | null {
    state.settings.forEach((item) => {
      if (item.name === SettingsTypes.EXPIRE_DATE) {
        return item.value;
      }
    });

    return localStorage.getItem("expire_date") || null;
  },
  [SettingGetters.GET_BILL_MESSAGE](): string | null {
    return localStorage.getItem(SettingsTypes.BILL_MESSAGE) || null;
  },
  [SettingGetters.GET_IS_SHOW_OBJECT_NAME](): string | null {
    return localStorage.getItem(SettingsTypes.IS_SHOW_OBJECT_NAME) || null;
  },
  [SettingGetters.GET_IS_PRINT_DOUBLE_BILL](): string | null {
    return localStorage.getItem(SettingsTypes.IS_PRINT_DOUBLE_BILL) || null;
  },
  [SettingGetters.GET_LOGO](): string | null {
    return localStorage.getItem(SettingsTypes.LOGO) || null;
  },
};
