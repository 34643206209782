import { Module } from "vuex";

import { IRootState } from "@/store/types";
import { getters } from "@/store/city/getters";
import { actions } from "@/store/city/actions";
import { mutations } from "@/store/city/mutations";
import { ICityState, state } from "@/store/city/state";

export const city: Module<ICityState, IRootState> = {
  actions,
  mutations,
  state,
  getters,
};
