import { IDataProvider } from "@/store/types";

export interface IRevision {
  id?: number;
  userId: number;
  amount: string;
  createdAt: string;
}

export interface IRevisionItem {
  id?: number;
  revisionId: number;
  productId: number;
  quantityInStock: string;
  quantityInFact: string;
  priceIn: string;
  balance: string;
  createdAt?: string;
}

export interface IRevisionState {
  revision: IRevision | null;
  revisions: IRevision[];
  dataProvider: IDataProvider<IRevision>;
}

export const state: IRevisionState = {
  revision: null,
  revisions: [],
  dataProvider: {
    currentPage: 0,
    records: [],
    totalItems: 0,
    totalPages: 0,
  },
};
