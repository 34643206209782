import { Module } from "vuex";

import { IRootState } from "@/store/types";
import { getters } from "@/store/supply/getters";
import { actions } from "@/store/supply/actions";
import { mutations } from "@/store/supply/mutations";
import { ISupplyState, state } from "@/store/supply/state";

export const supply: Module<ISupplyState, IRootState> = {
  actions,
  mutations,
  state,
  getters,
};
