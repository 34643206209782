import { ActionTree } from "vuex";
import axios from "axios";

import { IProductState, IProduct } from "@/store/product/state";
import { IRootState } from "@/store/types";
import { ProductMutations } from "@/store/product/mutations";
import {baseApi, getUrlAddress} from "@/store/config";

export enum ProductActions {
  FETCH_ALL = "FETCH_ALL_PRODUCT",
  FETCH_ONE = "FETCH_ONE_PRODUCT",
  FETCH_LAZY_DATA = "FETCH_LAZY_DATA_PRODUCT",
}

const routeName = `product`;

export const actions: ActionTree<IProductState, IRootState> = {
  [ProductActions.FETCH_LAZY_DATA]({ commit }, params) {
    return new Promise((resolve, reject) => {
      baseApi
        .get(`${getUrlAddress(routeName)}/data-provider`, {
          params: params,
        })
        .then((response) => {
          commit(ProductMutations.SET_LAZY_DATA, response.data);
          resolve(true);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  [ProductActions.FETCH_ALL]({ commit }, params) {
    return new Promise((resolve, reject) => {
      baseApi
        .get(`${getUrlAddress(routeName)}/index`, {
          params: params,
        })
        .then((response) => {
          commit(ProductMutations.SET_ALL, response.data);
          resolve(true);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  [ProductActions.FETCH_ONE]({ commit }, id) {
    return new Promise((resolve, reject) => {
      baseApi
        .get(`${getUrlAddress(routeName)}/get-by-id?id=${id}`)
        .then((response) => {
          commit(ProductMutations.SET_ONE, response.data);
          resolve(true);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
};
