import { MutationTree } from "vuex";
import { IRegionState, IRegion } from "@/store/region/state";
import { IDataProvider } from "@/store/types";

export enum RegionMutations {
  SET_ONE = "SET_ONE_REGION",
  SET_ALL = "SET_ALL_REGION",
  SET_LAZY_DATA = "SET_LAZY_DATA_REGION",
}

export const mutations: MutationTree<IRegionState> = {
  [RegionMutations.SET_ONE](state, payload: IRegion) {
    state.region = payload;
  },
  [RegionMutations.SET_ALL](state, payload: IRegion[]) {
    state.regions = payload;
  },
  [RegionMutations.SET_LAZY_DATA](state, payload: IDataProvider<IRegion>) {
    state.dataProvider = payload;
  },
};
