import { Module } from "vuex";

import { IRootState } from "@/store/types";
import { getters } from "@/store/setting/getters";
import { actions } from "@/store/setting/actions";
import { mutations } from "@/store/setting/mutations";
import { ISettingState, state } from "@/store/setting/state";

export const setting: Module<ISettingState, IRootState> = {
  actions,
  mutations,
  state,
  getters,
};
