import { Module } from "vuex";

import { IRootState } from "@/store/types";
import { getters } from "@/store/hall/getters";
import { actions } from "@/store/hall/actions";
import { mutations } from "@/store/hall/mutations";
import { IHallState, state } from "@/store/hall/state";

export const hall: Module<IHallState, IRootState> = {
  actions,
  mutations,
  state,
  getters,
};
