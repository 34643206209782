import { IDataProvider } from "@/store/types";

export interface ICity {
  id?: number;
  name: string;
  regionId: number;
}

export interface ICityState {
  city: ICity | null;
  cities: ICity[];
  dataProvider: IDataProvider<ICity>;
}

export const state: ICityState = {
  city: null,
  cities: [],
  dataProvider: {
    currentPage: 0,
    records: [],
    totalItems: 0,
    totalPages: 0,
  },
};
