import { GetterTree } from "vuex";
import { IPackState, IPack } from "@/store/pack/state";
import { IDataProvider, IRootState } from "@/store/types";

export enum PackGetters {
  GET_ALL = "GET_ALL_PACK",
  GET_CURRENT = "GET_CURRENT_PACK",
  GET_DATA_PROVIDER = "GET_DATA_PROVIDER_PACK",
}

export const getters: GetterTree<IPackState, IRootState> = {
  [PackGetters.GET_ALL](state): IPack[] {
    return state.packs;
  },
  [PackGetters.GET_CURRENT](state): IPack | null {
    return state.pack;
  },
  [PackGetters.GET_DATA_PROVIDER](state): IDataProvider<IPack> {
    return state.dataProvider;
  },
};
