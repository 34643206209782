import { GetterTree } from "vuex";
import { IAccountState, IAccount } from "@/store/account/state";
import { IDataProvider, IRootState } from "@/store/types";

export enum AccountGetters {
  GET_ALL = "GET_ALL_ACCOUNT",
  GET_CURRENT = "GET_CURRENT_ACCOUNT",
  GET_DATA_PROVIDER = "GET_DATA_PROVIDER_ACCOUNT",
}

export const getters: GetterTree<IAccountState, IRootState> = {
  [AccountGetters.GET_ALL](state): IAccount[] {
    return state.accounts;
  },
  [AccountGetters.GET_CURRENT](state): IAccount {
    return state.account;
  },
  [AccountGetters.GET_DATA_PROVIDER](state): IDataProvider<IAccount> {
    return state.dataProvider;
  },
};
