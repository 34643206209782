import { ActionTree } from "vuex";

import { ITransactionState, ITransaction } from "@/store/transaction/state";
import { IRootState } from "@/store/types";
import { TransactionMutations } from "@/store/transaction/mutations";
import { baseApi, getUrlAddress } from "@/store/config";

export enum TransactionActions {
  CREATE_ITEM = "CREAT_ITEM_TRANSACTION",
  FETCH_ONE = "FETCH_ONE_TRANSACTION",
  FETCH_ALL = "FETCH_ALL_TRANSACTION",
  FETCH_LAZY_DATA = "FETCH_LAZY_DATA_TRANSACTION",
  UPDATE_ITEM = "UPDATE_ITEM_TRANSACTION",
  DELETE_ITEM = "DELETE_ITEM_TRANSACTION",
  SET_ITEM = "SET_ITEM_TRANSACTION",
}

const routeName = `transaction`;

export const actions: ActionTree<ITransactionState, IRootState> = {
  [TransactionActions.FETCH_LAZY_DATA]({ commit }, params) {
    return new Promise((resolve, reject) => {
      baseApi
        .get(`${getUrlAddress(routeName)}/data-provider`, {
          params: params,
        })
        .then((response) => {
          commit(TransactionMutations.SET_LAZY_DATA, response.data);
          resolve(true);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  [TransactionActions.FETCH_ALL]({ commit }, params) {
    return new Promise((resolve, reject) => {
      baseApi
        .get(`${getUrlAddress(routeName)}/index`, {
          params: params,
        })
        .then((response) => {
          commit(TransactionMutations.SET_ALL, response.data);
          resolve(true);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  [TransactionActions.FETCH_ONE]({ commit }, id) {
    return new Promise((resolve, reject) => {
      baseApi
        .get(`${getUrlAddress(routeName)}/get`, {
          params: id,
        })
        .then((response) => {
          commit(TransactionMutations.SET_ONE, response.data);
          resolve(true);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  [TransactionActions.SET_ITEM]({ commit }, payload) {
    commit(TransactionMutations.SET_ONE, payload);
  },
};
