import { MutationTree } from "vuex";
import { ITakeOffState, ITakeOff } from "@/store/takeOff/state";
import { IDataProvider } from "@/store/types";

export enum TakeOffMutations {
  SET_ONE = "SET_TAKE_OFF",
  SET_ALL = "SET_ALL_TAKE_OFF",
  SET_LAZY_DATA = "SET_LAZY_DATA_TAKE_OFF",
}

export const mutations: MutationTree<ITakeOffState> = {
  [TakeOffMutations.SET_ONE](state, payload: ITakeOff) {
    state.takeOff = payload;
  },
  [TakeOffMutations.SET_ALL](state, payload: ITakeOff[]) {
    state.takeOffs = payload;
  },
  [TakeOffMutations.SET_LAZY_DATA](state, payload: IDataProvider<ITakeOff>) {
    state.dataProvider = payload;
  },
};
