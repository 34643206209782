import { ActionTree } from "vuex";
import axios from "axios";

import { ISetting, ISettingState } from "@/store/setting/state";
import { IRootState } from "@/store/types";
import { SettingMutations } from "@/store/setting/mutations";
import { baseApi, getUrlAddress } from "@/store/config";

export enum SettingActions {
  GET_ALL = "GET_ALL_SETTING",
  GET_ONE = "GET_ONE_SETTING",
  UPDATE_ITEM = "UPDATE_ITEM_SETTING",
  UPDATE_EXPIRE_DATE = "UPDATE_EXPIRE_DATE",
  ACTIVATE = "ACTIVATE",
}

const routeName = "settings";

export const actions: ActionTree<ISettingState, IRootState> = {
  [SettingActions.GET_ALL]({ commit }) {
    return new Promise((resolve, reject) => {
      baseApi
        .get(`${getUrlAddress(routeName)}/all`)
        .then((response) => {
          commit(SettingMutations.SET_ALL, response.data);
          resolve(true);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  [SettingActions.GET_ONE]({ commit }, payload) {
    return new Promise((resolve, reject) => {
      baseApi
        .get(`${getUrlAddress(routeName)}/get-by-name?name=${payload}`)
        .then((response) => {
          commit(SettingMutations.SET_ONE, response.data);
          resolve(true);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  [SettingActions.UPDATE_ITEM]({ dispatch }, payload: ISetting) {
    return new Promise((resolve, reject) => {
      baseApi
        .put(`${getUrlAddress(routeName)}/update`, {
          data: payload,
        })
        .then(() => {
          resolve(true);
        })
        .catch(function (error) {
          reject(error);
        });
    });
  },
  [SettingActions.UPDATE_EXPIRE_DATE]({ commit }) {
    return new Promise((resolve, reject) => {
      baseApi
        .get(`${getUrlAddress(routeName)}/update-expire-date`)
        .then(async (response) => {
          commit(SettingMutations.SET_EXPIRE_DATE, response.data);
          resolve(true);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  [SettingActions.ACTIVATE]({ commit }, payload) {
    return new Promise((resolve, reject) => {
      baseApi
        .post(`${getUrlAddress(routeName)}/activate`, {
          token: payload,
        })
        .then(function (response) {
          console.log(response);
          resolve(true);
        })
        .catch((error) => {
          reject(error);
          throw error;
        });
    });
  },
};
