import { GetterTree } from "vuex";
import { IRevisionState, IRevision } from "@/store/revision/state";
import { IDataProvider, IRootState } from "@/store/types";

export enum RevisionGetters {
  GET_ALL = "GET_ALL_REVISION",
  GET_CURRENT = "GET_CURRENT_REVISION",
  GET_DATA_PROVIDER = "GET_DATA_PROVIDER_REVISION",
}

export const getters: GetterTree<IRevisionState, IRootState> = {
  [RevisionGetters.GET_ALL](state): IRevision[] {
    return state.revisions;
  },
  [RevisionGetters.GET_CURRENT](state): IRevision | null {
    return state.revision;
  },
  [RevisionGetters.GET_DATA_PROVIDER](state): IDataProvider<IRevision> {
    return state.dataProvider;
  },
};
