import { Module } from "vuex";

import { IRootState } from "@/store/types";
import { getters } from "@/store/account/getters";
import { actions } from "@/store/account/actions";
import { mutations } from "@/store/account/mutations";
import { IAccountState, state } from "@/store/account/state";

export const account: Module<IAccountState, IRootState> = {
  actions,
  mutations,
  state,
  getters,
};
