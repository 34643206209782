import { ActionTree } from "vuex";
import axios from "axios";

import { IWorkshopState, IWorkshop } from "@/store/workshop/state";
import { IRootState } from "@/store/types";
import { WorkshopMutations } from "@/store/workshop/mutations";
import { baseApi, getUrlAddress } from "@/store/config";

export enum WorkshopActions {
  FETCH_ALL = "FETCH_ALL_WORKSHOP",
  FETCH_ONE = "FETCH_ONE_WORKSHOP",
  FETCH_LAZY_DATA = "FETCH_LAZY_DATA_WORKSHOP",
}

const routeName = `workshop`;

export const actions: ActionTree<IWorkshopState, IRootState> = {
  [WorkshopActions.FETCH_LAZY_DATA]({ commit }, params) {
    return new Promise((resolve, reject) => {
      baseApi
        .get(`${getUrlAddress(routeName)}/data-provider`, {
          params: params,
        })
        .then((response) => {
          commit(WorkshopMutations.SET_LAZY_DATA, response.data);
          resolve(true);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  [WorkshopActions.FETCH_ALL]({ commit }, params) {
    return new Promise((resolve, reject) => {
      baseApi
        .get(`${getUrlAddress(routeName)}/index`, {
          params: params,
        })
        .then((response) => {
          commit(WorkshopMutations.SET_ALL, response.data);
          resolve(true);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  [WorkshopActions.FETCH_ONE]({ commit }, id) {
    return new Promise((resolve, reject) => {
      baseApi
        .get(`${getUrlAddress(routeName)}/${id}`)
        .then((response) => {
          commit(WorkshopMutations.SET_ONE, response.data);
          resolve(true);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
};
