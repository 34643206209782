<template>
  <transition>
    <TheLoader v-if="loader" />
  </transition>

  <div :class="containerClass" @click="onWrapperClick">
    <AppTopBar @menu-toggle="onMenuToggle" />

    <transition name="layout-sidebar">
      <div
        :class="sidebarClass"
        @click="onSidebarClick"
        v-show="isSidebarVisible()"
      >
        <div class="layout-logo">
          <router-link to="/">
            <img alt="Logo" src="@/assets/logo.svg" style="width: 50%" />
          </router-link>
        </div>

        <AppProfile />
        <AppMenu :model="menu" @menuitem-click="onMenuItemClick" class="mt-4" />
      </div>
    </transition>

    <div class="layout-main">
      <router-view />
    </div>

    <AppFooter />
  </div>
</template>

<script>
import AppTopBar from "./AppTopbar.vue";
import AppMenu from "./AppMenu.vue";
import AppFooter from "./AppFooter.vue";
import AppProfile from "./AppProfile.vue";
import { LoaderGetters } from "@/store/loader";
import TheLoader from "@/components/app/TheLoader";

export default {
  components: {
    AppTopBar,
    AppMenu,
    AppProfile,
    AppFooter,
    TheLoader,
  },
  data() {
    return {
      layoutMode: "static",
      layoutColorMode: "light",
      staticMenuInactive: false,
      overlayMenuActive: false,
      mobileMenuActive: false,
      menu: [
        { label: "Главная страница", icon: "pi pi-fw pi-home", to: "/" },
        {
          label: "Статистика",
          icon: "pi pi-fw pi-chart-bar",
          items: [
            {
              label: "Продажи",
              icon: "pi pi-fw pi-id-card",
              to: "/statistics/sales",
            },
            {
              label: "Клиенты",
              icon: "pi pi-fw pi-check-square",
              to: "/input",
              disabled: true,
            },
            {
              label: "Цехи",
              icon: "pi pi-fw pi-bookmark",
              to: "/floatlabel",
              disabled: true,
            },
            {
              label: "Столы",
              icon: "pi pi-fw pi-exclamation-circle",
              to: "invalidstate",
              disabled: true,
            },
            {
              label: "Категории",
              icon: "pi pi-fw pi-list",
              to: "/button",
              disabled: true,
            },
            {
              label: "Товары",
              icon: "pi pi-fw pi-table",
              to: "/table",
              disabled: true,
            },
            {
              label: "ABC-анализ",
              icon: "pi pi-fw pi-list",
              to: "/list",
              disabled: true,
            },
            {
              label: "Чеки",
              icon: "pi pi-fw pi-share-alt",
              to: "/statistics/order",
            },
            {
              label: "Оплаты",
              icon: "pi pi-fw pi-tablet",
              to: "/statistics/payment",
            },
            {
              label: "Налоги",
              icon: "pi pi-fw pi-clone",
              to: "/overlay",
              disabled: true,
            },
          ],
        },
        {
          label: "Финансы",
          icon: "pi pi-fw pi-dollar",
          items: [
            {
              label: "Транзакции",
              icon: "pi pi-fw pi-desktop",
              to: "/finance/transaction",
            },
            {
              label: "Отчет",
              icon: "pi pi-fw pi-directions",
              disabled: true,
              to: "/flexbox",
            },
            {
              label: "Кассовые смены",
              icon: "pi pi-fw pi-search",
              to: "/finance/shift",
            },
            {
              label: "Зарплата",
              icon: "pi pi-fw pi-arrow-right",
              disabled: true,
              to: "/spacing",
            },
            {
              label: "Счета",
              icon: "pi pi-fw pi-align-center",
              to: "/finance/account",
            },
            {
              label: "Категории",
              icon: "pi pi-fw pi-pencil",
              to: "/finance/transaction-category",
            },
          ],
        },
        {
          label: "Меню",
          icon: "pi pi-fw pi-book",
          items: [
            {
              label: "Товары",
              icon: "pi pi-fw pi-circle-off",
              to: "/menu/product",
            },
            {
              label: "Тех. карты",
              icon: "pi pi-fw pi-circle-off",
              to: "/menu/dish",
            },
            {
              label: "Полуфабрикаты",
              icon: "pi pi-fw pi-circle-off",
              to: "/menu/semi-manufacture",
            },
            {
              label: "Ингредиенты",
              icon: "pi pi-fw pi-circle-off",
              to: "/menu/ingredient",
            },
            {
              label: "Категории товаров и тех. карты",
              icon: "pi pi-fw pi-list",
              to: "/menu/category-product",
            },
            {
              label: "Категории игредиентов",
              icon: "pi pi-fw pi-list",
              to: "/menu/category-ingredient",
            },
            {
              label: "Цехи",
              icon: "pi pi-fw pi-home",
              to: "/menu/workshop",
            },
          ],
        },
        {
          label: "Склад",
          icon: "pi pi-fw pi-home",
          items: [
            {
              label: "Остатки",
              icon: "pi pi-fw pi-inbox",
              to: "/warehouse/stock",
            },
            {
              label: "Поставки",
              icon: "pi pi-fw pi-shopping-cart",
              to: "/warehouse/supply",
            },
            {
              label: "Перемещение",
              icon: "pi pi-fw pi-circle-off",
              disabled: true,
              to: "/empty",
            },
            {
              label: "Списание",
              icon: "pi pi-fw pi-circle-off",
              to: "/warehouse/takeOff",
            },
            {
              label: "Отчет по движению",
              icon: "pi pi-fw pi-circle-off",
              disabled: true,
              to: "/empty",
            },
            {
              label: "Инвентаризации",
              icon: "pi pi-fw pi-circle-off",
              to: "/warehouse/revision",
            },
            {
              label: "Поставщики",
              icon: "pi pi-fw pi-user",
              to: "/warehouse/supplier",
            },
            {
              label: "Склады",
              icon: "pi pi-fw pi-shopping-cart",
              to: "/warehouse",
            },
            {
              label: "Фасовки",
              icon: "pi pi-fw pi-circle-off",
              to: "/warehouse/pack",
            },
          ],
        },
        {
          label: "Маркетинг",
          icon: "pi pi-fw pi-chart-line",
          items: [
            {
              label: "Клиенты",
              icon: "pi pi-fw pi-circle-off",
              to: "/marketing/customer",
            },
            {
              label: "Группы клиентов",
              icon: "pi pi-fw pi-circle-off",
              disabled: true,
              to: "/empty",
            },
            {
              label: "Программы лоялности",
              icon: "pi pi-fw pi-circle-off",
              disabled: true,
              to: "/empty",
            },
            {
              label: "Исключение",
              icon: "pi pi-fw pi-circle-off",
              disabled: true,
              to: "/empty",
            },
            {
              label: "Акции",
              icon: "pi pi-fw pi-circle-off",
              disabled: true,
              to: "/empty",
            },
          ],
        },
        {
          label: "Доступ",
          icon: "pi pi-fw pi-search",
          items: [
            {
              label: "Сотрудники",
              icon: "pi pi-fw pi-circle-off",
              to: "/user",
            },
            {
              label: "Должности",
              icon: "pi pi-fw pi-circle-off",
              disabled: true,
              to: "/empty",
            },
            {
              label: "Терминалы",
              icon: "pi pi-fw pi-circle-off",
              disabled: true,
              to: "/empty",
            },
            {
              label: "Заведения",
              icon: "pi pi-fw pi-circle-off",
              disabled: true,
              to: "/empty",
            },
            {
              label: "Интеграции",
              icon: "pi pi-fw pi-circle-off",
              disabled: true,
              to: "/empty",
            },
          ],
        },
        {
          label: "Настройки",
          icon: "pi pi-fw pi-search",
          items: [
            {
              label: "Общие",
              icon: "pi pi-fw pi-circle-off",
              disabled: true,
              to: "/empty",
            },
            {
              label: "Оплата подписки",
              icon: "pi pi-fw pi-circle-off",
              disabled: true,
              to: "/empty",
            },
            {
              label: "Доставка",
              icon: "pi pi-fw pi-circle-off",
              disabled: true,
              to: "/empty",
            },
            {
              label: "Безопасность",
              icon: "pi pi-fw pi-circle-off",
              disabled: true,
              to: "/empty",
            },
            {
              label: "Методы оплаты заказа",
              icon: "pi pi-fw pi-circle-off",
              disabled: true,
              to: "/empty",
            },
          ],
        },
        { label: "Загрузки", icon: "pi pi-fw pi-download", to: "/download" },
        { label: "Профиль", icon: "pi pi-fw pi-user", to: "/profile" },
        {
          label: "Оплата IMS",
          icon: "pi pi-fw pi-user",
          to: "/payments",
        },
      ],
    };
  },
  watch: {
    $route() {
      this.menuActive = false;
      this.$toast.removeAllGroups();
    },
  },
  methods: {
    onWrapperClick() {
      if (!this.menuClick) {
        this.overlayMenuActive = false;
        this.mobileMenuActive = false;
      }

      this.menuClick = false;
    },
    onMenuToggle() {
      this.menuClick = true;

      if (this.isDesktop()) {
        if (this.layoutMode === "overlay") {
          if (this.mobileMenuActive === true) {
            this.overlayMenuActive = true;
          }

          this.overlayMenuActive = !this.overlayMenuActive;
          this.mobileMenuActive = false;
        } else if (this.layoutMode === "static") {
          this.staticMenuInactive = !this.staticMenuInactive;
        }
      } else {
        this.mobileMenuActive = !this.mobileMenuActive;
      }

      event.preventDefault();
    },
    onSidebarClick() {
      this.menuClick = true;
    },
    onMenuItemClick(event) {
      if (event.item && !event.item.items) {
        this.overlayMenuActive = false;
        this.mobileMenuActive = false;
      }
    },
    onLayoutChange(layoutMode) {
      this.layoutMode = layoutMode;
    },
    onLayoutColorChange(layoutColorMode) {
      this.layoutColorMode = layoutColorMode;
    },
    addClass(element, className) {
      if (element.classList) element.classList.add(className);
      else element.className += " " + className;
    },
    removeClass(element, className) {
      if (element.classList) element.classList.remove(className);
      else
        element.className = element.className.replace(
          new RegExp(
            "(^|\\b)" + className.split(" ").join("|") + "(\\b|$)",
            "gi"
          ),
          " "
        );
    },
    isDesktop() {
      return window.innerWidth > 1024;
    },
    isSidebarVisible() {
      if (this.isDesktop()) {
        if (this.layoutMode === "static") return !this.staticMenuInactive;
        else if (this.layoutMode === "overlay") return this.overlayMenuActive;
        else return true;
      } else {
        return true;
      }
    },
  },
  computed: {
    layout() {
      return (this.$route.meta.layout || "main") + "-layout";
    },
    containerClass() {
      return [
        "layout-wrapper",
        {
          "layout-overlay": this.layoutMode === "overlay",
          "layout-static": this.layoutMode === "static",
          "layout-static-sidebar-inactive":
            this.staticMenuInactive && this.layoutMode === "static",
          "layout-overlay-sidebar-active":
            this.overlayMenuActive && this.layoutMode === "overlay",
          "layout-mobile-sidebar-active": this.mobileMenuActive,
          "p-input-filled": this.$appState.inputStyle === "filled",
          "p-ripple-disabled": this.$primevue.config.ripple === false,
        },
      ];
    },
    sidebarClass() {
      return [
        "layout-sidebar",
        {
          "layout-sidebar-dark": this.layoutColorMode === "dark",
          "layout-sidebar-light": this.layoutColorMode === "light",
        },
      ];
    },
    loader() {
      return this.$store.getters[LoaderGetters.GET_LOADER];
    },
  },
  beforeUpdate() {
    if (this.mobileMenuActive)
      this.addClass(document.body, "body-overflow-hidden");
    else this.removeClass(document.body, "body-overflow-hidden");
  },
};
</script>

<style lang="scss">
@import "@/App.scss";
@import "~boxicons/css/boxicons.min.css";
</style>
