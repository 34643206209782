import { ActionTree } from "vuex";
import axios from "axios";

import { IWarehouseState, IWarehouse } from "@/store/warehouse/state";
import { IRootState } from "@/store/types";
import { WarehouseMutations } from "@/store/warehouse/mutations";
import {baseApi, getUrlAddress} from "@/store/config";

export enum WarehouseActions {
  FETCH_ALL = "FETCH_ALL_WAREHOUSE",
  FETCH_ONE = "FETCH_ONE_WAREHOUSE",
  FETCH_LAZY_DATA = "FETCH_LAZY_DATA_WAREHOUSE",
}

const routeName = `warehouse`;

export const actions: ActionTree<IWarehouseState, IRootState> = {
  [WarehouseActions.FETCH_LAZY_DATA]({ commit }, params) {
    return new Promise((resolve, reject) => {
      baseApi
        .get(`${getUrlAddress(routeName)}/data-provider`, {
          params: params,
        })
        .then((response) => {
          commit(WarehouseMutations.SET_LAZY_DATA, response.data);
          resolve(true);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  [WarehouseActions.FETCH_ALL]({ commit }, params) {
    return new Promise((resolve, reject) => {
      baseApi
        .get(`${getUrlAddress(routeName)}/index`, {
          params: params,
        })
        .then((response) => {
          commit(WarehouseMutations.SET_ALL, response.data);
          resolve(true);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  [WarehouseActions.FETCH_ONE]({ commit }, id) {
    return new Promise((resolve, reject) => {
      baseApi
        .get(`${getUrlAddress(routeName)}/${id}`)
        .then((response) => {
          commit(WarehouseMutations.SET_ONE, response.data);
          resolve(true);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
};
