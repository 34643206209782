import { ActionTree } from "vuex";

import { IRevisionState, IRevision } from "@/store/revision/state";
import { IRootState } from "@/store/types";
import { RevisionMutations } from "@/store/revision/mutations";
import { baseApi, getUrlAddress } from "@/store/config";

export enum RevisionActions {
  FETCH_ALL = "FETCH_ALL_REVISION",
  FETCH_ONE = "FETCH_ONE_REVISION",
  FETCH_LAZY_DATA = "FETCH_LAZY_DATA_REVISION",
}

const routeName = `revision`;

export const actions: ActionTree<IRevisionState, IRootState> = {
  [RevisionActions.FETCH_LAZY_DATA]({ commit }, params) {
    return new Promise((resolve, reject) => {
      baseApi
        .get(`${getUrlAddress(routeName)}/data-provider`, {
          params: params,
        })
        .then((response) => {
          commit(RevisionMutations.SET_LAZY_DATA, response.data);
          resolve(true);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  [RevisionActions.FETCH_ALL]({ commit }, params) {
    return new Promise((resolve, reject) => {
      baseApi
        .get(`${getUrlAddress(routeName)}/index`, {
          params: params,
        })
        .then((response) => {
          commit(RevisionMutations.SET_ALL, response.data);
          resolve(true);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  [RevisionActions.FETCH_ONE]({ commit }, id) {
    return new Promise((resolve, reject) => {
      baseApi
        .get(`${getUrlAddress(routeName)}/get`, {
          params: {
            id,
          },
        })
        .then((response) => {
          commit(RevisionMutations.SET_ONE, response.data);
          resolve(true);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
};
