import { ActionTree } from "vuex";
import axios from "axios";

import { IOrderState, IOrder } from "@/store/order/state";
import { IRootState } from "@/store/types";
import { OrderMutations } from "@/store/order/mutations";
import {baseApi, getUrlAddress} from "@/store/config";

export enum OrderActions {
  FETCH_ONE = "FETCH_ONE_ORDER",
  FETCH_ALL = "FETCH_ALL_ORDER",
  FETCH_LAZY_DATA = "FETCH_LAZY_DATA_ORDER",
}

const routeName = `order`;

export const actions: ActionTree<IOrderState, IRootState> = {
  [OrderActions.FETCH_LAZY_DATA]({ commit }, params) {
    return new Promise((resolve, reject) => {
      baseApi
        .get(`${getUrlAddress(routeName)}/data-provider`, {
          params: params,
        })
        .then((response) => {
          commit(OrderMutations.SET_LAZY_DATA, response.data);
          resolve(true);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  [OrderActions.FETCH_ALL]({ commit }, params) {
    return new Promise((resolve, reject) => {
      baseApi
        .get(`${getUrlAddress(routeName)}/index`, {
          params: params,
        })
        .then((response) => {
          commit(OrderMutations.SET_ALL, response.data);
          resolve(true);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  [OrderActions.FETCH_ONE]({ commit }, id) {
    return new Promise((resolve, reject) => {
      baseApi
        .get(`${getUrlAddress(routeName)}/${id}`)
        .then((response) => {
          commit(OrderMutations.SET_ONE, response.data);
          resolve(true);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
};
