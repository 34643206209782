import { IHallState } from "@/store/hall/state";

export interface IPlan {
  id?: number;
  hallId?: number;
  number?: number;
  top: number;
  left: number;
  width: number;
  height: number;
  angle: number;
  radius: number;
  type: string;
}

export interface IPlanState {
  plans: IPlan[];
}

export const state: IPlanState = {
  plans: [],
};
