import { IDataProvider } from "@/store/types";

export interface ISupply {
  id?: number;
  supplierId: number;
  warehouseId: number;
  date: Date;
  isPaid?: boolean;
  paidAmount?: number;
  cost?: number;
  payDate?: Date;
  comment?: string;
}

export interface ISupplyState {
  supply: ISupply | null;
  supplies: ISupply[];
  dataProvider: IDataProvider<ISupply>;
}

export const state: ISupplyState = {
  supply: null,
  supplies: [],
  dataProvider: {
    currentPage: 0,
    records: [],
    totalItems: 0,
    totalPages: 0,
  },
};
