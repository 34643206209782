export interface IHall {
  id?: number;
  name: string;
  createdAt?: string;
  updatedAt?: string;
}

export interface IHallState {
  hall: IHall;
  halls: IHall[];
}

export const state: IHallState = {
  hall: {
    name: "",
  },
  halls: [],
};
