import { GetterTree } from "vuex";
import { IPaymentState, IPayment } from "@/store/payment/state";
import { IDataProvider, IRootState } from "@/store/types";

export enum PaymentGetters {
  GET_ALL = "GET_ALL_PAYMENT",
  GET_CURRENT = "GET_CURRENT_PAYMENT",
  GET_DATA_PROVIDER = "GET_DATA_PROVIDER_PAYMENT",
}

export const getters: GetterTree<IPaymentState, IRootState> = {
  [PaymentGetters.GET_ALL](state): IPayment[] {
    return state.payments;
  },
  [PaymentGetters.GET_CURRENT](state): IPayment | null {
    return state.payment;
  },
  [PaymentGetters.GET_DATA_PROVIDER](state): IDataProvider<IPayment> {
    return state.dataProvider;
  },
};
