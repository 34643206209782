import { IDataProvider } from "@/store/types";

export interface IRegion {
  id?: number;
  name: string;
}

export interface IRegionState {
  region: IRegion | null;
  regions: IRegion[];
  dataProvider: IDataProvider<IRegion>;
}

export const state: IRegionState = {
  region: null,
  regions: [],
  dataProvider: {
    currentPage: 0,
    records: [],
    totalItems: 0,
    totalPages: 0,
  },
};
