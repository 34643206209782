import { GetterTree } from "vuex";
import { IPlan, IPlanState } from "@/store/plan/state";
import { IRootState } from "@/store/types";

export enum PlanGetters {
  GET_ALL = "GET_ALL_PLAN",
}

export const getters: GetterTree<IPlanState, IRootState> = {
  [PlanGetters.GET_ALL](state): IPlan[] {
    return state.plans;
  },
};
