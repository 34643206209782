import { MutationTree } from "vuex";
import { IAccountState, IAccount } from "@/store/account/state";
import { IDataProvider } from "@/store/types";

export enum AccountMutations {
  SET_ONE = "SET_ONE_ACCOUNT",
  SET_ALL = "SET_ALL_ACCOUNT",
  SET_LAZY_DATA = "SET_LAZY_DATA_ACCOUNT",
}

export const mutations: MutationTree<IAccountState> = {
  [AccountMutations.SET_ONE](state, payload: IAccount) {
    state.account = payload;
  },
  [AccountMutations.SET_ALL](state, payload: IAccount[]) {
    state.accounts = payload;
  },
  [AccountMutations.SET_LAZY_DATA](state, payload: IDataProvider<IAccount>) {
    state.dataProvider = payload;
  },
};
