import { GetterTree } from "vuex";
import {
  ITransactionCategory,
  ITransactionCategoryState,
} from "@/store/transactionCategory/state";

import { IDataProvider, IRootState } from "@/store/types";

export enum TransactionCategoryGetters {
  GET_ALL = "GET_ALL_TRANSACTION_CATEGORY",
  GET_CURRENT = "GET_CURRENT_TRANSACTION_CATEGORY",
  GET_DATA_PROVIDER = "GET_DATA_PROVIDER_TRANSACTION_CATEGORY",
}

export const getters: GetterTree<ITransactionCategoryState, IRootState> = {
  [TransactionCategoryGetters.GET_ALL](state): ITransactionCategory[] {
    return state.transactionCategories;
  },
  [TransactionCategoryGetters.GET_CURRENT](state): ITransactionCategory | null {
    return state.transactionCategory;
  },
  [TransactionCategoryGetters.GET_DATA_PROVIDER](
    state
  ): IDataProvider<ITransactionCategory> {
    return state.dataProvider;
  },
};
