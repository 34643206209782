import { ActionTree } from "vuex";
import axios from "axios";

import { IPlan, IPlanState } from "@/store/plan/state";
import { IRootState } from "@/store/types";
import { PlanMutations } from "@/store/plan/mutations";
import { baseApi, getUrlAddress } from "@/store/config";

export enum PlanActions {
  FETCH_BY_HALL = "FETCH_PLANS_BY_HALL",
}

const routeName = `plan`;

export const actions: ActionTree<IPlanState, IRootState> = {
  [PlanActions.FETCH_BY_HALL]({ commit }, data: number) {
    return new Promise((resolve, reject) => {
      baseApi
        .get(`${getUrlAddress(routeName)}/get-by-hall`, {
          params: {
            id: data,
          },
        })
        .then((response) => {
          commit(PlanMutations.SET_ALL, response.data);
          resolve(true);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
};
