import { MutationTree } from "vuex";
import { ISettingState, ISetting } from "@/store/setting/state";

export enum SettingMutations {
  SET_ONE = "SET_ONE_SETTINGS",
  SET_ALL = "SET_ALL_SETTINGS",
  SET_EXPIRE_DATE = "SET_EXPIRE_DATE",
}

export const mutations: MutationTree<ISettingState> = {
  [SettingMutations.SET_ONE](state, payload: ISetting) {
    state.setting = payload;
  },
  [SettingMutations.SET_ALL](state, payload: ISetting[]) {
    for (const item of payload) {
      localStorage[item.name] = item.value;
    }

    state.settings = payload;
  },
  [SettingMutations.SET_EXPIRE_DATE](state, payload: string) {
    localStorage.setItem("expire_date", payload);
    state.settings.forEach((item) => {
      if (item.name === "expire_date") {
        item.value = payload;
        return;
      }
    });
  },
};
