import { MutationTree } from "vuex";
import { IPackState, IPack } from "@/store/pack/state";
import { IDataProvider } from "@/store/types";

export enum PackMutations {
  SET_ONE = "SET_ONE_PACKAGE",
  SET_ALL = "SET_ALL_PACKAGE",
  SET_LAZY_DATA = "SET_LAZY_DATA_PACKAGE",
}

export const mutations: MutationTree<IPackState> = {
  [PackMutations.SET_ONE](state, payload: IPack) {
    state.pack = payload;
  },
  [PackMutations.SET_ALL](state, payload: IPack[]) {
    state.packs = payload;
  },
  [PackMutations.SET_LAZY_DATA](state, payload: IDataProvider<IPack>) {
    state.dataProvider = payload;
  },
};
