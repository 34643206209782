import { MutationTree } from "vuex";
import { ISupplyState, ISupply } from "@/store/supply/state";
import { IDataProvider } from "@/store/types";

export enum SupplyMutations {
  SET_ONE = "SET_ONE_SUPPLY",
  SET_ALL = "SET_ALL_SUPPLY",
  SET_LAZY_DATA = "SET_LAZY_DATA_SUPPLY",
}

export const mutations: MutationTree<ISupplyState> = {
  [SupplyMutations.SET_ONE](state, payload: ISupply) {
    state.supply = payload;
  },
  [SupplyMutations.SET_ALL](state, payload: ISupply[]) {
    state.supplies = payload;
  },
  [SupplyMutations.SET_LAZY_DATA](state, payload: IDataProvider<ISupply>) {
    state.dataProvider = payload;
  },
};
