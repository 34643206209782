export const convertDateToMySqlDateTimeFormat = (date: Date) => {
  let month = (date.getMonth() + 1).toString();
  if (month.length === 1) {
    month = "0" + month;
  }

  let day = date.getDate().toString();
  if (day.length === 1) {
    day = "0" + day;
  }

  return date.getFullYear() + "-" + month + "-" + day + ' ' + date.toLocaleTimeString();
};
