import { MutationTree } from "vuex";
import {
  ITransactionCategoryState,
  ITransactionCategory,
} from "@/store/transactionCategory/state";
import { IDataProvider } from "@/store/types";

export enum TransactionCategoryMutations {
  SET_ONE = "SET_ONE_TRANSACTION_CATEGORY",
  SET_ALL = "SET_ALL_TRANSACTION_CATEGORY",
  SET_LAZY_DATA = "SET_LAZY_DATA_TRANSACTION_CATEGORY",
}

export const mutations: MutationTree<ITransactionCategoryState> = {
  [TransactionCategoryMutations.SET_ONE](state, payload: ITransactionCategory) {
    state.transactionCategory = payload;
  },
  [TransactionCategoryMutations.SET_ALL](state, payload: ITransactionCategory[]) {
    state.transactionCategories = payload;
  },
  [TransactionCategoryMutations.SET_LAZY_DATA](
    state,
    payload: IDataProvider<ITransactionCategory>
  ) {
    state.dataProvider = payload;
  },
};
