import { IDataProvider } from "@/store/types";

export interface IPack {
  id?: number;
  name: string;
  unit: string;
  quantity: number;
}

export enum PackUnits {
  PIECE = "PIECE",
  KG = "KG",
  L = "L",
}

export const packUnits = [
  {
    label: "Шт.",
    value: PackUnits.PIECE,
  },
  {
    label: "Кг.",
    value: PackUnits.KG,
  },
  {
    label: "Л.",
    value: PackUnits.L,
  },
];

export interface IPackState {
  pack: IPack | null;
  packs: IPack[];
  dataProvider: IDataProvider<IPack>;
}

export const state: IPackState = {
  pack: null,
  packs: [],
  dataProvider: {
    currentPage: 0,
    records: [],
    totalItems: 0,
    totalPages: 0,
  },
};
