import { MutationTree } from "vuex";
import { ISupplierState, ISupplier } from "@/store/supplier/state";
import { IDataProvider } from "@/store/types";

export enum SupplierMutations {
  SET_ONE = "SET_ONE_SUPPLIER",
  SET_ALL = "SET_ALL_SUPPLIER",
  SET_LAZY_DATA = "SET_LAZY_DATA",
}

export const mutations: MutationTree<ISupplierState> = {
  [SupplierMutations.SET_ONE](state, payload: ISupplier) {
    state.supplier = payload;
  },
  [SupplierMutations.SET_ALL](state, payload: ISupplier[]) {
    state.suppliers = payload;
  },
  [SupplierMutations.SET_LAZY_DATA](state, payload: IDataProvider<ISupplier>) {
    state.dataProvider = payload;
  },
};
