import { ActionTree } from "vuex";

import { IFacilityState, IFacility } from "@/store/facility/state";
import { IRootState } from "@/store/types";
import { FacilityMutations } from "@/store/facility/mutations";
import { baseApi, getUrlAddress } from "@/store/config";

export enum FacilityActions {
  CREATE_ITEM = "CREAT_ITEM_FACILITY",
  FETCH_ONE = "FETCH_ONE_FACILITY",
  UPDATE_ITEM = "UPDATE_ITEM_FACILITY",
  REGISTER_FACILITY = "REGISTER_FACILITY",
}

const routeName = `facility`;

export const actions: ActionTree<IFacilityState, IRootState> = {
  [FacilityActions.FETCH_ONE]({ commit }, id) {
    return new Promise((resolve, reject) => {
      baseApi
        .get(`${getUrlAddress(routeName)}/${id}`)
        .then((response) => {
          commit(FacilityMutations.SET_ONE, response.data);
          resolve(true);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  [FacilityActions.CREATE_ITEM]({ commit, dispatch }, payload: IFacility) {
    return new Promise((resolve, reject) => {
      baseApi
        .post(`${getUrlAddress(routeName)}/create`, payload)
        .then(() => {
          commit(FacilityMutations.SET_ONE, payload);
          resolve(true);
        })
        .catch(function (error) {
          reject(error);
        });
    });
  },
  [FacilityActions.UPDATE_ITEM]({ dispatch }, payload: IFacility) {
    return new Promise((resolve, reject) => {
      baseApi
        .put(`${getUrlAddress(routeName)}/update?id=${payload.id}`, {
          data: payload,
        })
        .then(() => {
          resolve(true);
        })
        .catch(function (error) {
          reject(error);
        });
    });
  },
  [FacilityActions.REGISTER_FACILITY]({ commit }, payload: IFacility) {
    return new Promise((resolve, reject) => {
      baseApi
        .post(`${getUrlAddress(routeName)}/register`, payload)
        .then(() => {
          resolve(payload);
        })
        .catch(function (error) {
          reject(error);
        });
    });
  },
};
