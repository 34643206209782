import { GetterTree } from "vuex";
import { ITransaction, ITransactionState } from "@/store/transaction/state";

import { IDataProvider, IRootState } from "@/store/types";

export enum TransactionGetters {
  GET_ALL = "GET_ALL_TRANSACTION",
  GET_CURRENT = "GET_CURRENT_TRANSACTION",
  GET_DATA_PROVIDER = "GET_DATA_PROVIDER_TRANSACTION",
}

export const getters: GetterTree<ITransactionState, IRootState> = {
  [TransactionGetters.GET_ALL](state): ITransaction[] {
    return state.transactions;
  },
  [TransactionGetters.GET_CURRENT](state): ITransaction | null {
    return state.transaction;
  },
  [TransactionGetters.GET_DATA_PROVIDER](state): IDataProvider<ITransaction> {
    return state.dataProvider;
  },
};
