import { IDataProvider } from "@/store/types";

export interface ITakeOff {
  id?: number;
  comment: string;
  createdAt: string;
}

export interface ITakeOffItem {
  id?: number;
  takeOffId: number;
  productId: number;
  quantity: number;
  createdAt?: string;
}

export interface ITakeOffState {
  takeOff: ITakeOff | null;
  takeOffs: ITakeOff[];
  dataProvider: IDataProvider<ITakeOff>;
}

export const state: ITakeOffState = {
  takeOff: null,
  takeOffs: [],
  dataProvider: {
    currentPage: 0,
    records: [],
    totalItems: 0,
    totalPages: 0,
  },
};
