import { ActionTree } from "vuex";
import axios from "axios";

import { ISupplierState, ISupplier } from "@/store/supplier/state";
import { IRootState } from "@/store/types";
import { SupplierMutations } from "@/store/supplier/mutations";
import {baseApi, getUrlAddress} from "@/store/config";

export enum SupplierActions {
  FETCH_ALL = "FETCH_ALL_SUPPLIER",
  FETCH_ONE = "FETCH_ONE_SUPPLIER",
  FETCH_LAZY_DATA = "FETCH_LAZY_DATA_SUPPLIER",
}

const routeName = `supplier`;

export const actions: ActionTree<ISupplierState, IRootState> = {
  [SupplierActions.FETCH_LAZY_DATA]({ commit }, params) {
    return new Promise((resolve, reject) => {
      baseApi
        .get(`${getUrlAddress(routeName)}/data-provider`, {
          params: params,
        })
        .then((response) => {
          commit(SupplierMutations.SET_LAZY_DATA, response.data);
          resolve(true);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  [SupplierActions.FETCH_ALL]({ commit }, params) {
    return new Promise((resolve, reject) => {
      baseApi
        .get(`${getUrlAddress(routeName)}/index`, {
          params: params,
        })
        .then((response) => {
          commit(SupplierMutations.SET_ALL, response.data);
          resolve(true);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  [SupplierActions.FETCH_ONE]({ commit }, id) {
    return new Promise((resolve, reject) => {
      baseApi
        .get(`${getUrlAddress(routeName)}/${id}`)
        .then((response) => {
          commit(SupplierMutations.SET_ONE, response.data);
          resolve(true);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
};
