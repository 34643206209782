import { Module } from "vuex";

import { IRootState } from "@/store/types";
import { IPlanState, state } from "@/store/plan/state";
import { getters } from "@/store/plan/getters";
import { mutations } from "@/store/plan/mutations";
import { actions } from "@/store/plan/actions";

export const plan: Module<IPlanState, IRootState> = {
  actions,
  mutations,
  state,
  getters,
};
