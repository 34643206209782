import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import Sales from "../views/statistics/Sales.vue";
import PageNotFound from "@/views/PageNotFound.vue";

import { store } from "@/store";
import { UserRoles } from "@/store/user/state";
import { AuthGetters } from "@/store/auth";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    redirect: "/statistics/sales",
  },
  {
    path: "/login",
    name: "Login",
    meta: { layout: "auth", auth: false },
    component: () => import("../views/auth/Login.vue"),
  },
  {
    path: "/signup",
    name: "Signup",
    meta: { layout: "auth", auth: false },
    component: () => import("../views/auth/Signup.vue"),
  },
  {
    path: "/profile",
    name: "Profile",
    meta: { auth: true },
    component: () => import("../views/Profile.vue"),
  },
  {
    path: "/payments",
    name: "Payments",
    meta: { auth: true },
    component: () => import("../views/Payments.vue"),
  },
  {
    path: "/createFacility",
    name: "CreateFacility",
    meta: { layout: "auth", auth: false },
    component: () => import("../views/auth/CreateFacility.vue"),
  },
  {
    path: "/settings/plan",
    name: "Plan",
    meta: { auth: true },
    component: () => import("../views/settings/Plan.vue"),
  },
  {
    path: "/settings/bill",
    name: "Bill",
    meta: { auth: true },
    component: () => import("../views/settings/Bill.vue"),
  },
  {
    path: "/warehouse/supply",
    name: "Supply",
    meta: { auth: true },
    component: () => import("../views/warehouse/supply/Index.vue"),
  },
  {
    path: "/warehouse/supplier",
    name: "Supplier",
    meta: { auth: true },
    component: () => import("../views/warehouse/supplier/Index.vue"),
  },
  {
    path: "/warehouse/takeOff",
    name: "TakeOff",
    meta: { auth: true },
    component: () => import("../views/warehouse/takeOff/Index.vue"),
  },
  {
    path: "/warehouse/takeOff/view",
    name: "TakeOffView",
    meta: { auth: true },
    component: () => import("../views/warehouse/takeOff/View.vue"),
  },
  {
    path: "/warehouse/revision",
    name: "Revision",
    meta: { auth: true },
    component: () => import("../views/warehouse/revision/Index.vue"),
  },
  {
    path: "/warehouse/revision/view",
    name: "RevisionView",
    meta: { auth: true },
    component: () => import("../views/warehouse/revision/View.vue"),
  },
  {
    path: "/warehouse/pack",
    name: "Pack",
    meta: { auth: true },
    component: () => import("../views/warehouse/pack/Index.vue"),
  },
  {
    path: "/warehouse",
    name: "Warehouse",
    meta: { auth: true },
    component: () => import("../views/warehouse/Index.vue"),
  },
  {
    path: "/finance/account",
    name: "Account",
    meta: { auth: true },
    component: () => import("../views/finance/account/Index.vue"),
  },
  {
    path: "/menu/product",
    name: "Product",
    meta: { auth: true },
    component: () => import("../views/menu/product/Index.vue"),
  },
  {
    path: "/menu/ingredient",
    name: "Ingredient",
    meta: { auth: true },
    component: () => import("../views/menu/ingredient/Index.vue"),
  },
  {
    path: "/menu/category-product",
    name: "CategoryProduct",
    meta: { auth: true },
    component: () => import("../views/menu/category-product/Index.vue"),
  },
  {
    path: "/menu/category-ingredient",
    name: "CategoryIngredient",
    meta: { auth: true },
    component: () => import("../views/menu/category-ingredient/Index.vue"),
  },
  {
    path: "/menu/workshop",
    name: "Workshop",
    meta: { auth: true },
    component: () => import("../views/menu/workshop/Index.vue"),
  },
  {
    path: "/menu/semi-manufacture",
    name: "SemiManufacture",
    meta: { auth: true },
    component: () => import("../views/menu/semi-manufacture/Index.vue"),
  },
  {
    path: "/menu/dish",
    name: "Dish",
    meta: { auth: true },
    component: () => import("../views/menu/dish/Index.vue"),
  },
  {
    path: "/warehouse/stock",
    name: "Stock",
    meta: { auth: true },
    component: () => import("../views/warehouse/Stock.vue"),
  },
  {
    path: "/user",
    name: "User",
    meta: { auth: true },
    component: () => import("../views/user/Index.vue"),
  },
  {
    path: "/finance/shift",
    name: "Shift",
    meta: { auth: true },
    component: () => import("../views/finance/shift/Index.vue"),
  },
  {
    path: "/finance/transaction-category",
    name: "TransactionCategory",
    meta: { auth: true },
    component: () => import("../views/finance/transaction-category/Index.vue"),
  },
  {
    path: "/finance/transaction",
    name: "Transaction",
    meta: { auth: true },
    component: () => import("../views/finance/transaction/Index.vue"),
  },
  {
    path: "/statistics/order",
    name: "Order",
    meta: { auth: true },
    component: () => import("../views/statistics/Order.vue"),
  },
  {
    path: "/statistics/sales",
    name: "Sales",
    meta: { auth: true },
    component: Sales,
  },
  {
    path: "/statistics/payment",
    name: "StatisticsPayment",
    meta: { auth: true },
    component: () => import("../views/statistics/Payment.vue"),
  },
  {
    path: "/marketing/customer",
    name: "Customer",
    meta: { auth: true },
    component: () => import("../views/marketing/customer/Index.vue"),
  },
  {
    path: "/download",
    name: "Download",
    meta: { auth: true },
    component: () => import("../views/Download.vue"),
  },
  {
    path: "/:pathMatch(.*)*",
    meta: { layout: "empty", auth: false },
    component: PageNotFound,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {
  if (!to.meta.auth) {
    next();
    return;
  }

  if (to.meta.auth) {
    console.log();
    if (
      store.getters[AuthGetters.GET_IS_AUTHENTICATED] &&
      store.getters[AuthGetters.GET_ROLE] !== UserRoles.WAITER
    ) {
      next();
    } else {
      next({ name: "Login" });
    }
  }
});

export default router;
