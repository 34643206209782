import { GetterTree } from "vuex";
import { ITakeOffState, ITakeOff } from "@/store/takeOff/state";
import { IDataProvider, IRootState } from "@/store/types";

export enum TakeOffGetters {
  GET_ALL = "GET_ALL_TAKE_OFF",
  GET_CURRENT = "GET_CURRENT_TAKE_OFF",
  GET_DATA_PROVIDER = "GET_DATA_PRODVIDER_TAKE_OFF",
}

export const getters: GetterTree<ITakeOffState, IRootState> = {
  [TakeOffGetters.GET_ALL](state): ITakeOff[] {
    return state.takeOffs;
  },
  [TakeOffGetters.GET_CURRENT](state): ITakeOff | null {
    return state.takeOff;
  },
  [TakeOffGetters.GET_DATA_PROVIDER](state): IDataProvider<ITakeOff> {
    return state.dataProvider;
  },
};
