import { IDataProvider } from "@/store/types";

export interface IWorkshop {
  id?: number;
  name: string;
  isPrintSliders: boolean;
}

export interface IWorkshopState {
  workshop: IWorkshop;
  workshops: IWorkshop[];
  dataProvider: IDataProvider<IWorkshop>;
}

export const state: IWorkshopState = {
  workshop: {
    name: "",
    isPrintSliders: false,
  },
  workshops: [],
  dataProvider: {
    currentPage: 0,
    records: [],
    totalItems: 0,
    totalPages: 0,
  },
};
