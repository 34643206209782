import { GetterTree } from "vuex";
import { IRegionState, IRegion } from "@/store/region/state";

import { IDataProvider, IRootState } from "@/store/types";

export enum RegionGetters {
  GET_ALL = "GET_ALL_REGION",
  GET_CURRENT = "GET_CURRENT_REGION",
  GET_DATA_PROVIDER = "GET_DATA_PROVIDER_REGION",
}

export const getters: GetterTree<IRegionState, IRootState> = {
  [RegionGetters.GET_ALL](state): IRegion[] {
    return state.regions;
  },
  [RegionGetters.GET_CURRENT](state): IRegion | null {
    return state.region;
  },
  [RegionGetters.GET_DATA_PROVIDER](state): IDataProvider<IRegion> {
    return state.dataProvider;
  },
};
