import { IDataProvider } from "@/store/types";

export interface IWarehouse {
  id?: number;
  name: string;
  address?: string;
  balance?: number;
}

export interface IWarehouseState {
  warehouse: IWarehouse;
  warehouses: IWarehouse[];
  dataProvider: IDataProvider<IWarehouse>;
}

export const state: IWarehouseState = {
  warehouse: {
    name: "",
  },
  warehouses: [],
  dataProvider: {
    currentPage: 0,
    records: [],
    totalItems: 0,
    totalPages: 0,
  },
};
