import { IDataProvider } from "@/store/types";

export interface IPayment {
  id?: number;
  amount: number;
  userId: number;
  facilityId: number;
  tariff: string;
  month: number;
  createdAt: Date;
}

export interface IPaymentState {
  payment: IPayment | null;
  payments: IPayment[];
  dataProvider: IDataProvider<IPayment>;
}

export const state: IPaymentState = {
  payment: null,
  payments: [],
  dataProvider: {
    currentPage: 0,
    records: [],
    totalItems: 0,
    totalPages: 0,
  },
};
