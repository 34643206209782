import { MutationTree } from "vuex";
import { ICustomerState, ICustomer } from "@/store/customer/state";
import { IDataProvider } from "@/store/types";

export enum CustomerMutations {
  SET_ONE = "SET_ONE_CUSTOMER",
  SET_ALL = "SET_ALL_CUSTOMER",
  SET_LAZY_DATA = "SET_LAZY_DATA_CUSTOMER",
}

export const mutations: MutationTree<ICustomerState> = {
  [CustomerMutations.SET_ONE](state, payload: ICustomer) {
    state.customer = payload;
  },
  [CustomerMutations.SET_ALL](state, payload: ICustomer[]) {
    state.customers = payload;
  },
  [CustomerMutations.SET_LAZY_DATA](state, payload: IDataProvider<ICustomer>) {
    state.dataProvider = payload;
  },
};
