import { GetterTree } from "vuex";
import { IOrderState, IOrder } from "@/store/order/state";
import { IDataProvider, IRootState } from "@/store/types";

export enum OrderGetters {
  GET_ALL = "GET_ALL_ORDER",
  GET_CURRENT = "GET_CURRENT_ORDER",
  GET_DATA_PROVIDER = "GET_DATA_PROVIDER_ORDER",
}

export const getters: GetterTree<IOrderState, IRootState> = {
  [OrderGetters.GET_ALL](state): IOrder[] {
    return state.orders;
  },
  [OrderGetters.GET_CURRENT](state): IOrder | undefined {
    return state.order;
  },
  [OrderGetters.GET_DATA_PROVIDER](state): IDataProvider<IOrder> {
    return state.dataProvider;
  },
};
