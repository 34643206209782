import { Module } from "vuex";

import { IRootState } from "@/store/types";
import { getters } from "@/store/pack/getters";
import { actions } from "@/store/pack/actions";
import { mutations } from "@/store/pack/mutations";
import { IPackState, state } from "@/store/pack/state";

export const pack: Module<IPackState, IRootState> = {
  actions,
  mutations,
  state,
  getters,
};
